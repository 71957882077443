import { FormattedMessage } from "react-intl";
import Notification from "./Notification";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { notifications } from "../../redux/common";

const Notifications = () => {
  const dispatch = useDispatch();
  const notify = useSelector(notifications.selectors.isOpen);
  const notification = useSelector(notifications.selectors.getMessage);
  return (
    <Notification
      isOpen={notify}
      type={notification.type}
      onClose={() => dispatch(notifications.actions.clearNotification())}
    >
      {notification && notification.message && (
        <FormattedMessage {...notification.message} />
      )}
    </Notification>
  );
};

export default Notifications;
