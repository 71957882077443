import "./ConfirmationDialog.scss";

import BaseModal from "../BaseModal";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import React from "react";
import Tags from "../Tags";

const ConfirmationDialog = ({ isOpen, onCancel, onConfirm, children, message }) => {
  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={false}
      contentLabel="">
      <div className="ConfirmationDialog__content">
        <Tags.H1>
          {message}
        </Tags.H1>
        {children}
        <div >
          <Tags.FormButton onClick={onConfirm}>
            <FormattedMessage id="confirmationDialog.yes" />
          </Tags.FormButton>
          <Tags.FormButton className="ConfirmationDialog__disabled" onClick={onCancel}>
            <FormattedMessage id="confirmationDialog.no" />
          </Tags.FormButton>
        </div>
      </div>
    </BaseModal>
  );
};

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default ConfirmationDialog;