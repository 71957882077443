import "./SubscribedServiceCard.scss";

import { FormattedMessage, injectIntl } from "react-intl";
import PurchaseControls, { purchaseShape } from "../PurchaseControls";
import SubscribedService, { subscribedServiceShape } from "./SubscribedService";

import PropTypes from "prop-types";
import React from "react";
import SVG from "../SVG";
import Tags from "../Tags";
import classNames from "classnames";

const { LocationPin } = SVG;

const SubscribedServiceCard = ({
  data,
  action,
  actionLabel,
  theme = "default",
  buttonLess = false,
  actionIsALink = false,
  onIncrease = () => { },
  onDecrease = () => { },
  onChange = () => { },
  disabled,
  disabledReason,
  intl
}) => (
    <div className="SubscribedServiceCard">
      <div className="SubscribedServiceCard__ContentOuter">
        <div className="SubscribedServiceCard__ContentInner SubscribedServiceCard__ContentInner--Top">
          <LocationPin
            className="SubscribedServiceCard__ContentInner__icon"
            type={theme}
          />
          <p
            className="SubscribedServiceCard__ContentInner__title"
            title={data.title}
          >
            {data.title}
          </p>
          <FormattedMessage id="subscribedServiceCard.target">
            {txt => (
              <div
                className="SubscribedServiceCard__ContentInner__subitems"
                title={data.address}
              >
                <span className="SubscribedServiceCard__ContentInner__subitems__text">
                  {txt}
                </span>
                {data.address}
              </div>
            )}
          </FormattedMessage>

          <div className="SubscribedServiceCard__ContentInner__subitems">
            <span className="SubscribedServiceCard__ContentInner__subitems__text">
              <FormattedMessage id="subscribedServiceCard.capacity" />
            </span>
            <span>
              {" "}
              {!!data.capacity || data.capacity === 0
                ? data.capacity
                : intl.formatMessage({
                  id: "subscribedServiceCard.capacity.undefined"
                })}
            </span>
          </div>
          {data.pricingProfileInfo && data.pricingProfileInfo.length ?
          <div className="SubscribedServiceCard__ContentInner__subitems">
            <span className="SubscribedServiceCard__ContentInner__subitems__text">
              <FormattedMessage id="subscribedServiceCard.pricingProfile.info" />
            </span>
            <span>
                {data.pricingProfileInfo.join(', ')}
            </span>
          </div>
          : null
          }
        </div>
        <div className="SubscribedServiceCard__ContentInner SubscribedServiceCard__ContentInner--Bottom">
          {data.subscribedService ? (
            <SubscribedService subscribedService={data.subscribedService} />
          ) : (
              <PurchaseControls
                purchase={data.purchase}
                onIncrease={onIncrease}
                onDecrease={onDecrease}
                onChange={onChange}
              />
            )}
          {actionIsALink && (
            <Tags.A
              className="SubscribedServiceCard__actionLink"
              onClick={action}>
              {actionLabel}
            </Tags.A>
          )}
        </div>
      </div>

      {!buttonLess && (
        <button
          className={classNames({
            SubscribedServiceCard__action: true,
            "SubscribedServiceCard__action--gold": theme === "gold",
            "SubscribedServiceCard__action--disabled": disabled
          })}
          onClick={action}
          disabled={disabled}
          title={disabledReason ? disabledReason : ""}
        >
          {actionLabel}
        </button>
      )}
    </div>
  );

SubscribedServiceCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.node,
    address: PropTypes.node,
    subscribedService: subscribedServiceShape,
    purchase: purchaseShape
  }).isRequired,
  action: PropTypes.func,
  actionLabel: PropTypes.node,
  theme: PropTypes.string,
  buttonLess: PropTypes.bool,
  actionIsALink: PropTypes.bool,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
  onChange: PropTypes.func
};

export default injectIntl(SubscribedServiceCard);
