import * as yup from "yup";

import finland from "../lib/finland";
import phoneNumbers from "../lib/phoneNumbers";

export const companyTemplate = {
  name: "",
  businessId: "", // Y-tunnus
  address: "",
  postalCode: "",
  postOffice: ""
};

export const contactPersonTemplate = {
  firstName: "",
  familyName: "",
  email: "",
  phone: "",
  promotionOptIn: false
};

export const template = () => ({
  company: companyTemplate,
  contactPerson: contactPersonTemplate,
  approveTermsOfUse: false
});

export const contactPersonSchemaObject = intl =>
  yup.object({ contactPerson: contactPersonSchema(intl) });

const contactPersonSchema = intl =>
  yup.object({
    firstName: yup
      .string()
      .required(intl.formatMessage({ id: "wizard.contactPerson.firstName.valid" }))
      .min(
        2,
        intl.formatMessage({ id: "wizard.contactPerson.firstName.valid" })
      ),
    familyName: yup
      .string()
      .required(intl.formatMessage({ id: "wizard.contactPerson.familyName.valid" }))
      .min(
        2,
        intl.formatMessage({ id: "wizard.contactPerson.familyName.valid" })
      ),
    email: yup
      .string()
      .required(intl.formatMessage({ id: "wizard.contactPerson.email.valid" }))
      .email(intl.formatMessage({ id: "wizard.contactPerson.email.valid" })),
    phone: yup
      .string()
      .required(intl.formatMessage({ id: "wizard.contactPerson.phone.valid" }))
      .test(
        "valid-phone",
        intl.formatMessage({ id: "wizard.contactPerson.phone.valid" }),
        num => phoneNumbers.validPhoneNumber(num)
      ),
    promotionOptIn: yup.boolean().required()
  });

export const companySchemaObject = intl =>
  yup.object({ company: companySchema(intl) });

const companySchema = intl =>
  yup
    .object()
    .shape({
      name: yup
        .string()
        .required(intl.formatMessage({ id: "wizard.companyInfo.name.valid" }))
        .min(2, intl.formatMessage({ id: "wizard.companyInfo.name.valid" })),
      businessId: yup
        .string()
        .required(intl.formatMessage({ id: "wizard.companyInfo.businessId.valid" }))
        .test(
          "business-id",
          intl.formatMessage({ id: "wizard.companyInfo.businessId.valid" }),
          finland.validBusinessId
        ),
      address: yup
        .string()
        .required(intl.formatMessage({ id: "wizard.companyInfo.address.valid" }))
        .min(3, intl.formatMessage({ id: "wizard.companyInfo.address.valid" })),
      postalCode: yup
        .string()
        .required(intl.formatMessage({ id: "wizard.companyInfo.postalCode.valid" }))
        .test(
          "postal-code",
          intl.formatMessage({ id: "wizard.companyInfo.postalCode.valid" }),
          finland.validPostalCode
        ),
      postOffice: yup
        .string()
        .required(intl.formatMessage({ id: "wizard.companyInfo.postOffice.valid" }))
        .min(
          2,
          intl.formatMessage({ id: "wizard.companyInfo.postOffice.valid" })
        )
    })
    .required();

export const schema = intl =>
  yup.object({
    contactPerson: contactPersonSchema(intl),
    company: companySchema(intl),
    approveTermsOfUse: yup
      .boolean()
      .test(
        "approved-terms",
        intl.formatMessage({ id: "wizard.approveTermsOfUse" }),
        value => value
      )
      .required()
  });
