import "./UnassignedUser.scss";

import { FormattedMessage, injectIntl } from "react-intl";
import { Input, Tags } from "../..";
import { schema, template } from "./assignSubscription.schema";

import { Formik } from "formik";
import React from "react";
import { getError } from "../../../lib/form";

const { LinkButton } = Tags;

const UnassignedUser = ({
  user,
  assignUser,
  intl,
  unassignedNameRef,
  assignmentsRemaining
}) => (
  <Formik
    initialValues={template(user)}
    onSubmit={(values, actions) => {
      const requestData = {
        name: values.name,
        phoneNumber: values.phone,
        maxActiveVehicles: values.maxActiveVehicles
      };
      if (values.additionalInfo) {
        requestData.additionalInfo = values.additionalInfo;
      }
      assignUser(requestData, values.subscribedServiceRef)
        .finally(() => actions.setSubmitting(false))
        .then(() => {
          if (unassignedNameRef && unassignedNameRef.current) {
            unassignedNameRef.current.focus();
          }
          actions.resetForm();
        });
    }}
    validationSchema={schema(intl, assignmentsRemaining)}
  >
    {({
      handleSubmit,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      isValid,
      isSubmitting,
      submitForm
    }) => (
      <tr
        className="UnassignedUser"
        onKeyDown={e => {
          if (e.nativeEvent.key === "Enter") {
            submitForm();
          }
        }}
      >
        <td className="UnassignedUser__field">
          <Input
            className="UnassignedUser__field__name"
            type="text"
            name="name"
            placeholder={intl.formatMessage({
              id: "manage_subscription.assign.name"
            })}
            value={values.name}
            error={getError(touched, errors, "name")}
            onChange={handleChange}
            onBlur={handleBlur}
            inputRef={unassignedNameRef}
          />
        </td>
        <td className="UnassignedUser__field">
          <Input
            className="UnassignedUser__field__phone"
            type="text"
            name="phone"
            placeholder={intl.formatMessage({
              id: "manage_subscription.assign.phone"
            })}
            value={values.phone}
            error={getError(touched, errors, "phone")}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus={false}
          />
        </td>
        <td className="UnassignedUser__field">
          <Input
            className="UnassignedUser__field__maxActiveVehicles"
            type="number"
            min={1}
            max={assignmentsRemaining}
            name="maxActiveVehicles"
            value={values.maxActiveVehicles}
            error={getError(touched, errors, "maxActiveVehicles")}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </td>
        <td className="UnassignedUser__field" colSpan={2}>
          <Input
            className="UnassignedUser__field__name"
            type="text"
            name="additionalInfo"
            value={values.additionalInfo}
            error={getError(touched, errors, "additionalInfo")}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </td>
        <td className="UnassignedUser__action">
          <LinkButton
            onClick={handleSubmit}
            type="button"
            disabled={isSubmitting || !isValid}
          >
            <FormattedMessage id="manage_subscription.assign">
              {msg => `${msg} >`}
            </FormattedMessage>
          </LinkButton>
        </td>
      </tr>
    )}
  </Formik>
);

export default injectIntl(UnassignedUser);
