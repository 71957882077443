const upper = /[A-ZÅÄÖ]/;
const lower = /[a-zåäö]/;
const number = /\d/;

export const validPassword = pwd => {
  if (!(typeof pwd === "string" && pwd.length && pwd.length >= 8)) {
    return false;
  }
  return upper.test(pwd) && lower.test(pwd) && number.test(pwd);
};
