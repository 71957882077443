import "./ContactInfo.scss";

import { FormattedMessage, useIntl } from "react-intl";
import { schema, template } from "../../schemas/ContactInfo.schema";
import {
  signupParametersActions,
  signupParametersSelector,
} from "../../redux/signup";

import CompanyInfo from "./CompanyInfo";
import ContactPerson from "../ContactPersonForm";
import EnterpriseHeader from "../EnterpriseHeader";
import { Formik } from "formik";
import FormikCheckbox from "../FormikCheckbox";
import React from "react";
import SignupProgress from "../SignupProgress";
import { Tags } from "..";
import { useDispatch, useSelector } from "react-redux";
import { getError } from "../../lib/form";
import phoneNumbers from "../../lib/phoneNumbers";
import { useNavigate } from "react-router-dom";

const { View, FormButton, Page } = Tags;

const ContactInfo = () => {
  const dispatch = useDispatch();
  const signupParameters = useSelector(signupParametersSelector);
  const navigate = useNavigate();
  const intl = useIntl();

  const { storeSignupparameters } = signupParametersActions;
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    // Make sure that phone number is in international format
    values.contactPerson.phone = phoneNumbers.getInternationalPhoneNumber(
      values.contactPerson.phone
    );
    dispatch(storeSignupparameters(values));
    navigate("/signup/invoice");
  };

  const formData = signupParameters ? signupParameters : template();
  return (
    <div>
      <EnterpriseHeader title={<FormattedMessage id="wizard.title" />}>
        <SignupProgress step={1} />
      </EnterpriseHeader>
      <Page>
        <View>
          <Formik
            initialValues={formData}
            onSubmit={onSubmit}
            validationSchema={schema(intl)}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
            }) => (
              <form className="ContactInfo__form">
                <div className="ContactInfo__formBlockContainer">
                  {[CompanyInfo, ContactPerson].map((form) =>
                    form({
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      errors,
                      intl,
                    })
                  )}
                </div>

                <FormikCheckbox
                  className="ContactInfo__terms"
                  label={
                    <span>
                      <FormattedMessage id="wizard.approveTerms" /> &nbsp;
                      <a
                        href="https://moovy.fi/yrityssopimusehdot/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="wizard.ofUse" />
                      </a>
                    </span>
                  }
                  ariaLabel={intl.formatMessage(
                    { id: "wizard.approveAria" },
                    { url: "#" }
                  )}
                  value={values.approveTermsOfUse}
                  name="approveTermsOfUse"
                  onChange={handleChange}
                  error={getError(touched, errors, "approveTermsOfUse")}
                />
                <FormButton
                  onClick={handleSubmit}
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="wizard.submit" />
                </FormButton>
              </form>
            )}
          </Formik>
        </View>
      </Page>
    </div>
  );
};

export default ContactInfo;
