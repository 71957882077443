import * as yup from "yup";

import { validPassword } from "../../lib/passwordValidation";

export const template = () => ({
  credentials: {
    username: "",
    password: "",
    confirmPassword: "",
  },
});

export const schema = (intl) =>
  yup.object({
    credentials: yup.object({
      username: yup
        .string(intl.formatMessage({ id: "wizard.userPass.username.valid" }))
        .required()
        .min(6, intl.formatMessage({ id: "wizard.userPass.username.valid" }))
        .test(
          "no-whitespace",
          intl.formatMessage({ id: "wizard.userPass.username.whitespace" }),
          (value) => !value.match(/(\s)/g)
        ),
      password: yup
        .string()
        .required(intl.formatMessage({ id: "wizard.userPass.password.valid" }))
        .test(
          "valid-password",
          intl.formatMessage({ id: "wizard.userPass.password.valid" }),
          validPassword
        ),
      confirmPassword: yup
        .string()
        .required(intl.formatMessage({id: "wizard.invoiceInfo.generic.required"}))
        .when("password", (password, _schema) =>
          password
            ? _schema.test(
                "passwords-match",
                intl.formatMessage({
                  id: "wizard.userPass.confirmPassword.valid",
                }),
                (value) => value === password
              )
            : _schema
        ),
    }),
  });
