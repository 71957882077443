import { augmentResponse, mkDataStore } from "../redux";

import { DataStoreTypes } from "../constants";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import { createAction } from "redux-actions";
import { doesServicehaveDiscounts } from "../lib/helpers";
import { serviceShape } from "../shapes";

/**
 * we track purchases in redux, so we augment the generated store with
 * actions to handle this. we store UI-related attributes in under _ui key
 */
const server2client = augmentResponse({ count: 1 });

const key = "services";

const INCREASE_ORDER = `${key}/INCREASE_ORDER`;
const DECREASE_ORDER = `${key}/DECREASE_ORDER`;
const CHANGE_ORDER = `${key}/CHANGE_ORDER`;

const reducerSeed = {
  [INCREASE_ORDER]: (state, action) => {
    const key = action.payload;
    if (key && state[key]) {
      const retval = cloneDeep(state);
      retval[key]._ui.count += 1;
      return retval;
    }
    return state;
  },
  [DECREASE_ORDER]: (state, action) => {
    const key = action.payload;
    if (key && state[key] && state[key]._ui.count > 0) {
      const retval = cloneDeep(state);
      retval[key]._ui.count -= 1;
      return retval;
    }
    return state;
  },
  [CHANGE_ORDER]: (state, action) => {
    const { key, value } = action.payload;
    if (key && state[key]) {
      const retval = cloneDeep(state);
      retval[key]._ui.count = value;
      return retval;
    }
    return state;
  }
};

const store = mkDataStore(key, DataStoreTypes.Keyed, { reducerSeed });

export const servicesReducer = store.reducer;
export const servicesActions = {
  ...store.actions,
  increaseOrder: createAction(INCREASE_ORDER),
  decreaseOrder: createAction(DECREASE_ORDER),
  changeOrder: createAction(CHANGE_ORDER)
};
export const servicesSelectors = store.selectors;

export const servicesUtils = {
  getCount: service => service && service._ui.count,
  getMaxCapacity: service => service && Number.MAX_SAFE_INTEGER // FIXME: get the real capacity
};

const getServices = () => (dispatch, getState, apis) =>
  apis.moovyApi.services.getServices(dispatch).then(res => {
    if (res && res.data) {
      // Store the services
      dispatch(servicesActions.storeServices(server2client(res.data)));
      // Find the services with discounts and fetch the current price for each one
      const servicesWithDiscounts = res.data.filter(service => doesServicehaveDiscounts(service));
      servicesWithDiscounts.forEach(element => getDiscountedPrice(element.ref, element._ui.count)(dispatch, getState, apis));
    }
  });

const getDiscountedPrice = (serviceRef, count) => (dispatch, getState, apis) =>
  apis.moovyApi.services.getPricing(dispatch, {serviceRef, count}).then(res => {
    if (res) {
      // Add the discounted price to the service _ui property
      const services = getState()[key];
      const service = services[serviceRef];
      service._ui.discountedPrice = res.data.totalPrice;
      const updatedState = {...services, [serviceRef]: {...service}};
      dispatch(servicesActions.storeServices(updatedState));
    }
  });

export const servicesThunks = { getServices, getDiscountedPrice };

export const servicesUiShape = {
  ...serviceShape,
  _ui: PropTypes.shape({
    count: PropTypes.number
  })
};
