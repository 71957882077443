import { notifications, pending } from "../redux/common";

import { NotificationTypes } from "../constants";
// using axios in favor of fetch to avoid using a separate proxy server
import axios from "axios";

const pendingRestCalls = opts => {
  const { getToken, baseUrl, apiObject } = opts;

  const createConfig = (path, customOpts) => {
    const retval = {
      url: `${baseUrl}${path}`
    };
    if ((customOpts && customOpts.token) || getToken) {
      const token = (customOpts && customOpts.token) || getToken();
      if (token) {
        retval.headers = { authorization: `Bearer ${token}` };
      }
    }

    return retval;
  };

  const customContentType = (opts, contentType) => {
    if (contentType) {
      if (opts.headers) {
        opts.headers["Content-type"] = contentType;
      } else {
        opts.headers = { "Content-type": contentType };
      }
    }
    return opts;
  };

  const methods = {
    post: (path, data, customOpts) =>
      axios(
        customContentType(
          {
            ...createConfig(path, customOpts),
            method: "post",
            data
          },
          customOpts && customOpts.contentType
        )
      ),
    put: (path, data, customOpts) =>
      axios(
        customContentType(
          {
            ...createConfig(path, customOpts),
            method: "put",
            data
          },
          customOpts && customOpts.contentType
        )
      ),
    get: (path, params, customOpts) => {
      const config = createConfig(path, customOpts);
      if (params) {
        config.params = params;
      }
      return axios(config);
    }
  };

  // wraps an axios promise with error dialog if necessary
  const withErrorNotification = (dispatch, pendingApiCall, customOpts) =>
    pendingApiCall.catch(err => {
      const message =
        customOpts && customOpts.customErrorMessage
          ? customOpts.customErrorMessage
          : { id: "a.api_error" };
      dispatch(
        notifications.actions.setNotification(message, NotificationTypes.Error)
      );
      throw err;
    });

  // wraps a promise generated by api so that the progress can be tracked
  const withPending = (dispatch, promise) => {
    const id = pending.getPendingId();
    dispatch(pending.actions.startPending(id));
    return promise.finally(() => {
      dispatch(pending.actions.endPending(id));
    });
  };

  const wrapApiCall = apiOperation => (dispatch, dataOrParams, customOpts) => {
    const pendingApiCall = withPending(
      dispatch,
      apiOperation(dataOrParams, customOpts)
    );
    if (customOpts && customOpts.callerHandlesErrors) {
      return pendingApiCall;
    }
    return withErrorNotification(dispatch, pendingApiCall, customOpts);
  };

  const wrapApi = api =>
    Object.keys(api).reduce(
      (wrapped, operation) => ({
        ...wrapped,
        [operation]: wrapApiCall(api[operation])
      }),
      {}
    );

  // go through apis in the apiObject and wrap them
  return Object.keys(apiObject).reduce(
    (acc, apiKey) => ({
      ...acc,
      [apiKey]: wrapApi(apiObject[apiKey](methods))
    }),
    {}
  );
};

export default pendingRestCalls;