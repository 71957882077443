import { FormattedMessage } from "react-intl";
import { accountToInvoicing, invoicingToAccount } from "../lib/helpers";
import { enterpriseAccountThunks } from "../redux/account";

import { BackToAccount } from "../components/BackLink";
import EnterpriseHeader from "../components/EnterpriseHeader";
import InvoiceInformationForm from "../components/InvoiceInformationForm";
import React from "react";
import { Tags } from "../components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAccount from "../redux/useAccount";

const { View, Page } = Tags;

const InvoiceInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (values) => {
    dispatch(
      enterpriseAccountThunks.updateInvoicing(invoicingToAccount(values))
    ).then(() => {
      navigate("/");
    });
  };

  const account = useAccount();

  // Don't want to set the form initial values before we have account, proper loading state would be better
  if (!account) {
    return null;
  }

  return (
    <div>
      <EnterpriseHeader title={<FormattedMessage id="wizard.title" />} />
      <Page>
        <View>
          <BackToAccount />
          <InvoiceInformationForm
            initialValues={accountToInvoicing(account)}
            onSubmit={onSubmit}
          />
        </View>
      </Page>
    </div>
  );
};

export default InvoiceInfo;
