import { FormattedMessage } from "react-intl";
import LogoutButton from "./LogoutButton";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Navigation() {
  const navigate = useNavigate();
  const location = useLocation();

  const onVoucherPage = location.pathname === "/voucher";

  return (
    <nav>
      <ul className="Container">
        <li
          className={!onVoucherPage ? "Active" : "Link"}
          onClick={() => navigate("/")}
        >
          <FormattedMessage id="account.title" />
        </li>
        <li
          className={onVoucherPage ? "Active" : "Link"}
          onClick={() => navigate("/voucher")}
        >
          <FormattedMessage id="voucher.title" />
        </li>
        <li className="Logout">
          <LogoutButton />
        </li>
      </ul>
    </nav>
  );
}
