import "./ErrorBoundary.scss";

import * as Sentry from '@sentry/react';

import { FormattedMessage } from "react-intl";
import React from "react";
import { Tags } from "..";

const { View, H1 } = Tags;

function FallBackComponent() {
  return (
    <View className="ErrorBoundary">
      <H1 className="ErrorBoundary__title">
        <FormattedMessage
          id="error.title"
          description="Title of the error view."
        />
      </H1>
      <p>
        <FormattedMessage
          id="error.description"
          description="What happened, what to do next"
        />
      </p>
    </View>
  );
}


const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={<FallBackComponent />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;