import * as yup from "yup";

export const template = user => ({
  name: user.customName,
  additionalInfo: user.additionalInfo ? user.additionalInfo : "",
  maxActiveVehicles: user.maxActiveVehicles,
  phoneNumber: user.phoneNumber
});

export const schema = (intl, availableCapacity) =>
  yup.object().shape({
    name: yup
      .string()
      .required(intl.formatMessage({ id: "manage_subscription.name.required" }))
      .min(2, intl.formatMessage({ id: "manage_subscription.name.valid" })),
    additionalInfo: yup
      .string()
      .max(
        100,
        intl.formatMessage({ id: "manage_subscription.additionalInfo.max" })
      ),
    maxActiveVehicles: yup
      .number()
      .required(
        intl.formatMessage({
          id: "manage_subscription.maxActiveVehicles.required"
        })
      )
      .min(
        1,
        intl.formatMessage({ id: "manage_subscription.maxActiveVehicles.min" })
      )
      .max(
        availableCapacity,
        intl.formatMessage(
          { id: "manage_subscription.maxActiveVehicles.max" },
          { availableCapacity }
        )
      )
  });
