const countCosts = (customerBenefits) => customerBenefits.map(benefit => ({ ...benefit, amount: benefit.amount * 1.25 }));

const monthlyCustomerBenefits = [
  {
    date: new Date(), // Put this in here, put it shouldnt be needed
    amount: 234.5
  },
  {
    date: new Date(),
    amount: 134
  },
  {
    date: new Date(),
    amount: 289.2
  },
  {
    date: new Date(),
    amount: 50
  },
  {
    date: new Date(),
    amount: 70
  },
  {
    date: new Date(),
    amount: 89
  },
  {
    date: new Date(),
    amount: 145
  },
  {
    date: new Date(),
    amount: 111
  },
  {
    date: new Date(),
    amount: 12
  },
  {
    date: new Date(),
    amount: 52
  },
  {
    date: new Date(),
    amount: 139
  },
  {
    date: new Date(),
    amount: 400
  },
];

export const fakeCostsData = {
  monthlyCustomerBenefits,
  monthlyCosts: countCosts(monthlyCustomerBenefits),
};