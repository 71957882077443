/**
 * Formik-specific form block
 */

import FormBlock from "../../components/FormBlock";
import { Input } from "..";
import React from "react";
import companyEditFormShape from "../../shapes/companyEditFormShape";
import { getError } from "../../lib/form";

const ExistingCompany = ({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  intl
}) => (
  <FormBlock titleId="wizard.companyInfo" key="CompanyInfo">
    <Input
      type="text"
      name="company.name"
      label={intl.formatMessage({
        id: "wizard.companyInfo.name.label"
      })}
      value={values.company.name}
      error={getError(touched, errors, "company.name")}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    <Input
      type="text"
      name="company.address"
      label={intl.formatMessage({
        id: "wizard.companyInfo.address.label"
      })}
      value={values.company.address}
      error={getError(touched, errors, "company.address")}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    <div className="ContactInfo__doubleInput">
      <Input
        className="ContactInfo__doubleInput__left"
        type="text"
        name="company.postalCode"
        label={intl.formatMessage({
          id: "wizard.companyInfo.postalCode.label"
        })}
        value={values.company.postalCode}
        error={getError(touched, errors, "company.postalCode")}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Input
        className="ContactInfo__doubleInput__right"
        type="text"
        name="company.postOffice"
        label={intl.formatMessage({
          id: "wizard.companyInfo.postOffice.label"
        })}
        value={values.company.postOffice}
        error={getError(touched, errors, "company.postOffice")}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  </FormBlock>
);

ExistingCompany.propTypes = companyEditFormShape;

export default ExistingCompany;
