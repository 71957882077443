import { DataStoreTypes } from "../constants";
import { createSelector } from "reselect";
import { mkDataStore } from "../redux";
import { project } from "../lib";
import { servicesSelectors } from "./services";
import { subscribedServicesSelectors } from "./subscribedServices";

const store = mkDataStore("enterpriseAccount", DataStoreTypes.Keyless);

export const enterpriseAccountReducer = store.reducer;
export const enterpriseAccountActions = store.actions;
export const enterpriseAccountSelector = store.selector;

const client2ServerSpec = {
  firstName: "contactPerson.firstName",
  lastName: "contactPerson.familyName",
  email: "contactPerson.email",
  phone: "contactPerson.phone",
  company: "company.name",
  billingMethod: "invoiceInfo.type",
  customInvoiceReference: "invoiceInfo.customInvoiceReference",
  eInvoiceOperator: "invoiceInfo.serviceId",
  eInvoiceOvtNumber: "invoiceInfo.ovt",
  streetAddress: "company.address",
  postalCode: "company.postalCode",
  city: "company.postOffice",
  username: "credentials.username",
  password: "credentials.password",
  emailMarketingAllowed: "contactPerson.promotionOptIn",
  businessCode: "company.businessId",
};

// trasform from client representation to server representation
const client2server = (source) => project(client2ServerSpec, source);

// thunks ---------------------------------------------------------------------
const createAccount = (values) => (dispatch, getState, apis) =>
  apis.moovyApi.enterprise
    .createEnterpriseAccount(dispatch, client2server(values), {
      callerHandlesErrors: true,
    })
    .then((res) => {
      if (res)
        dispatch(enterpriseAccountActions.storeEnterpriseAccount(res.data));
    });

const getAccount = () => (dispatch, getState, apis) =>
  apis.moovyApi.enterprise
    .getEnterpriseAccount(dispatch)
    .then(
      (res) =>
        res &&
        dispatch(enterpriseAccountActions.storeEnterpriseAccount(res.data))
    );

const updateAccount =
  (values, callerHandlesErrors = false) =>
  (dispatch, getState, apis) => {
    const currentAccount = enterpriseAccountSelector(getState());
    const { firstName, familyName, email, phone, promotionOptIn } =
      values.contactPerson;
    currentAccount.firstName = firstName;
    currentAccount.lastName = familyName;
    currentAccount.email = email;
    currentAccount.phone = phone;
    currentAccount.emailMarketingAllowed = promotionOptIn;

    return apis.moovyApi.enterprise
      .updateEnterpriseAccount(
        dispatch,
        { ...currentAccount },
        {
          callerHandlesErrors: true,
        }
      )
      .then((res) => {
        if (res)
          dispatch(enterpriseAccountActions.storeEnterpriseAccount(res.data));
      });
  };

const updateCompany = (values) => (dispatch, getState, apis) => {
  const currentAccount = enterpriseAccountSelector(getState());
  const { name, address, postalCode, postOffice } = values.company;
  currentAccount.company = name;
  currentAccount.streetAddress = address;
  currentAccount.postalCode = postalCode;
  currentAccount.city = postOffice;

  return apis.moovyApi.enterprise
    .updateEnterpriseAccount(dispatch, currentAccount)
    .then((res) => {
      if (res)
        dispatch(enterpriseAccountActions.storeEnterpriseAccount(res.data));
    });
};

const updateInvoicing = (values) => (dispatch, getState, apis) => {
  const currentAccount = enterpriseAccountSelector(getState());
  const updated = { ...currentAccount, ...values };
  return apis.moovyApi.enterprise
    .updateEnterpriseAccount(dispatch, updated)
    .then((res) => {
      if (res)
        dispatch(enterpriseAccountActions.storeEnterpriseAccount(res.data));
    });
};

export const enterpriseAccountThunks = {
  createAccount,
  updateAccount,
  updateCompany,
  updateInvoicing,
  getAccount,
};

/**
 * Process back-end responses to better suit the view
 * - services include all services, but we need to separate between
 *   - services that the customer has ordered
 *   - services that are specific to the customer
 *   - services that the customer has not ordered
 * - subscriptions are per-user, so we need to group them per-service
 */
export const getUnsubscribedServices = createSelector(
  subscribedServicesSelectors.getSubscribedServicesAsArray,
  servicesSelectors.getServicesAsArray,
  (subscribed, allServices) => {
    const subscribedRefs = subscribed.map(
      (subscribedService) => subscribedService.service.ref
    );
    return allServices.filter(
      (service) => !subscribedRefs.includes(service.ref)
    );
  }
);
