import "./SendVoucher.scss";

import { FormattedMessage, injectIntl } from "react-intl";
import React, { useEffect, useState } from "react";

import { NotificationTypes } from "../../../constants";
import { Tags } from "../..";
import VoucherCard from "../VoucherCard";
import VoucherSendingContainer from "./VoucherSendingContainer";
import { compose } from "redux";
import { connect } from "react-redux";
import { grantTypes } from "..";
import { notifications } from "../../../redux/common";
import phoneNumbers from "../../../lib/phoneNumbers";
import { useDebouncedCallback } from "use-debounce";
import { voucherThunks } from "../../../redux/vouchers";

const { H1 } = Tags;

const validatePhone = (phoneNumber) => {
  if (!phoneNumbers.validPhoneNumber(phoneNumber)) {
    return { message: "voucher.send.invalid.phoneNumber" };
  }
};

const SendVoucher = ({
  availableVouchers,
  giveVoucher,
  getOngoing,
  setNotification
}) => {
  const [isSending, setIsSending] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [templateRef, setTemplateRef] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [ongoingParkings, setOngoingParkings] = useState([]);
  const [ongoingParking, setOngoingParking] = useState();

  const debouncedFetchOngoing = useDebouncedCallback((value) => {
    setOngoingParking({ loading: true });
    getOngoing(templateRef, value).then((response) => {
      setOngoingParkings(response.data);
      setOngoingParking({ init: true });
    });
  }, 500);

  const onChangeLicensePlate = (e) => {
    const newValue = e.target.value.toUpperCase();
    setLicensePlate(newValue);
    if (newValue.length > 2) {
      debouncedFetchOngoing(newValue);
    } else {
      setOngoingParking({});
    }
  };

  const selectedVoucher = availableVouchers.find((v) => v.ref === templateRef);

  const resetState = () => {
    setPhoneNumber("");
    setLicensePlate("");
    setStartTime(new Date());
    setOngoingParking({});
  };

  useEffect(() => {
    // Just reinit these when another voucher selected
    resetState();
  }, [templateRef]);

  useEffect(() => {
    if (ongoingParkings.length === 1) {
      const ongoing = ongoingParkings[0];
      setStartTime(new Date(ongoing.startTime));
      setOngoingParking({ init: true, parking: ongoing });
    } else {
      setOngoingParking({ init: true, loading: false });
      setStartTime(new Date());
    }
  }, [ongoingParkings]);

  const send = () => {
    let validationError = null;

    switch (selectedVoucher.allowedGrantType) {
      case grantTypes.PHONE:
        validationError = validatePhone(phoneNumber);
        break;
      case grantTypes.LPN:
        validationError = !licensePlate
          ? { message: "voucher.send.licensePlateNumber.required" }
          : null;
        break;
      case grantTypes.BOTH:
        validationError =
          !licensePlate && !phoneNumber
            ? { message: "voucher.send.either.required" }
            : null;
        if (phoneNumber && !validationError) {
          validationError = validatePhone(phoneNumber);
        }
        break;
      default:
        break;
    }
    if (validationError) {
      setNotification({ id: validationError.message }, NotificationTypes.Error);
    } else {
      setIsSending(true);
      let voucherData = {};
      if (selectedVoucher.allowedGrantType === grantTypes.PHONE) {
        voucherData.phoneNumber = phoneNumbers.getInternationalPhoneNumber(
          phoneNumber
        );
      } else if (selectedVoucher.allowedGrantType === grantTypes.LPN) {
        voucherData.licensePlateNumber = licensePlate;
      } else {
        if (phoneNumber) {
          voucherData.phoneNumber = phoneNumbers.getInternationalPhoneNumber(
            phoneNumber
          );
        }
        if (licensePlate) {
          voucherData.licensePlateNumber = licensePlate;
        }
      }

      voucherData.startTime = startTime;
      giveVoucher(voucherData, templateRef).then(() => {
        setNotification(
          { id: "voucher.send.succeeded" },
          NotificationTypes.Success
        );
        setIsSending(false);
        resetState();
      });
    }
  };

  let expiration;

  if (selectedVoucher) {
    if (startTime) {
      expiration = new Date(
        startTime.getTime() + selectedVoucher.validForMinutes * 60000
      );
    } else {
      expiration = new Date(
        new Date() + selectedVoucher.validForMinutes * 60000
      );
    }
  }

  return (
    <div className="SendVoucher">
      <H1>
        <FormattedMessage id="voucher.select.title" />
      </H1>
      {availableVouchers &&
        availableVouchers.map((voucher) => (
          <VoucherCard
            key={voucher.ref}
            data={voucher}
            voucherRef={voucher.ref}
            checked={voucher.ref === templateRef}
            onChange={(ref) => () => setTemplateRef(ref)}
          >
            {voucher.ref === templateRef && (
              <VoucherSendingContainer
                allowedGrantType={voucher.allowedGrantType}
                disabled={isSending}
                phoneNumber={phoneNumber}
                onChangePhone={(e) => setPhoneNumber(e.target.value)}
                licensePlate={licensePlate}
                onChangeLicensePlate={onChangeLicensePlate}
                ongoingParking={ongoingParking}
                startTime={startTime}
                onChangeStartTime={(date) => setStartTime(date)}
                expiration={expiration}
                onSend={send}
              />
            )}
          </VoucherCard>
        ))}
    </div>
  );
};

export default compose(
  connect(undefined, {
    giveVoucher: voucherThunks.giveVoucher,
    getOngoing: voucherThunks.getOngoing,
    ...notifications.actions,
  }),
  injectIntl
)(SendVoucher);
