import "./Table.scss";

import Table, { propTypes as tablePropTypes } from ".";

import Pagination from "../Pagination";
import PropTypes from "prop-types";
import React from "react";

const PaginatedTable = ({
  headers,
  rows,
  renderRows,
  totalResults,
  currentPage,
  pageSize,
  changePage,
}) => (
  <>
    <Table headers={headers} rows={rows} renderRows={renderRows} />
    <div className="TablePagination">
      <Pagination
        pageSize={pageSize}
        currentPage={currentPage}
        totalResults={totalResults}
        changePage={changePage}
      />
    </div>
  </>
);

PaginatedTable.propTypes = {
  ...tablePropTypes,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};

export default PaginatedTable;
