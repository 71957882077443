/**
 * Date manipulation utilities.
 * moment.js is sooo big...
 */

export const parseISODate = isoString => {
  if (typeof isoString !== "string") {
    return null;
  }
  const [date, time] = isoString.split("T");

  const dates = date
    .split("-")
    .map(item => parseInt(item, 10))
    .filter(num => !isNaN(num));
  if (dates.length !== 3) {
    return null;
  }
  const [year, month, day] = dates;

  let hour = 0,
    minutes = 0,
    seconds = 0,
    milliseconds = 0;
  if (time) {
    const times = time.split(":");
    if (times.length > 1) {
      const _hour = parseInt(times[0], 10);
      hour = isNaN(_hour) ? 0 : _hour;
      const _minutes = parseInt(times[1], 10);
      minutes = isNaN(_minutes) ? 0 : _minutes;
      if (times.length === 3) {
        const secs = times[2].split(".");
        const _seconds = parseInt(secs[0], 10);
        seconds = isNaN(_seconds) ? 0 : _seconds;
        const _milliseconds = secs.length > 1 ? parseInt(secs[1].substring(0, 3), 10) : 0;
        milliseconds = isNaN(_milliseconds) ? 0 : _milliseconds;
      }
    }
  }
  return new Date(
    Date.UTC(year, month - 1, day, hour, minutes, seconds, milliseconds)
  );
};
