import "./SignupProgress.scss";

import { FormattedMessage, useIntl } from "react-intl";

import PropTypes from "prop-types";
import React from "react";
import { Tags } from "..";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

const { H1 } = Tags;

const routes = ["/signup", "/signup/invoice", "/signup/credentials"];

const SignupProgress = ({ step }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  return (
    <div className="SignupProgress">
      <H1 className="SignupProgress__title">
        <FormattedMessage id="wizard.setup_progress.title" />
      </H1>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 440 60"
        className="SignupProgress__svg"
      >
        {[42, 220, 398, 576].map((cx, index) => (
          <circle
            key={index}
            cx={cx}
            cy="17"
            r="17"
            className={classNames({
              SignupProgress__svg__circle: true,
              "SignupProgress__svg__circle--active": step - 1 === index,
              "SignupProgress__svg__circle--clickable": index < step - 1,
            })}
            onClick={() => {
              if (index < step - 1) navigate(routes[index]);
            }}
          />
        ))}
        {[38, 215, 393, 571].map((x, index) => (
          <text
            x={x}
            y="23"
            className="SignupProgress__svg__balloon"
            key={index}
            pointerEvents="none"
          >
            {index + 1}
          </text>
        ))}
        <line x1="92" y1="17" x2="172" y2="17" />
        <line x1="276" y1="17" x2="356" y2="17" />
        <line x1="460" y1="17" x2="540" y2="17" />

        <text x="3" y="57" className="SignupProgress__svg__steptitle">
          {intl.formatMessage({ id: "wizard.setup_progress_step1" })}
        </text>
        <text x="182" y="57" className="SignupProgress__svg__steptitle">
          {intl.formatMessage({ id: "wizard.setup_progress_step2" })}
        </text>
        <text x="372" y="57" className="SignupProgress__svg__steptitle">
          {intl.formatMessage({ id: "wizard.setup_progress_step3" })}
        </text>
        <text x="551" y="57" className="SignupProgress__svg__steptitle">
          {intl.formatMessage({ id: "wizard.setup_progress_step4" })}
        </text>
      </svg>
    </div>
  );
};
SignupProgress.propTypes = {
  step: PropTypes.number.isRequired,
};

export default SignupProgress;
