import "./FormBlock.scss";

import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import React from "react";
import { Tags } from "..";
import classNames from "classnames";

const FormBlock = ({ titleId, children, smallTitle }) => {
  const Title = smallTitle ? Tags.H2 : Tags.H1;
  const className = classNames({
    FormBlock: true,
    "FormBlock--smallTitle": smallTitle
  });

  return (
    <div className={className}>
      <Title className="FormBlock__title">
        <FormattedMessage id={titleId} />
      </Title>
      {children}
    </div>
  );
};

FormBlock.propTypes = {
  titleId: PropTypes.string.isRequired,
  smallTitle: PropTypes.bool
};

FormBlock.defaultProps = {
  smallTitle: false
};

export default FormBlock;
