import PropTypes from "prop-types";
import { serviceMediumShape } from "./services";
import { shortShape } from "./common";
import { userShortShape } from "./user";

export const subscriptionShape = {
  user: PropTypes.shape(userShortShape),
  owner: PropTypes.shape(userShortShape),
  currentUserIsOwner: PropTypes.bool,
  currentUserIsUser: PropTypes.bool,
  subscriptionPlan: PropTypes.object, // TODO
  service: PropTypes.shape(serviceMediumShape),
  activeVehicle: PropTypes.shape(shortShape),
  purchaseDate: PropTypes.string,
  startDate: PropTypes.string,
  cancelDate: PropTypes.string,
  endDate: PropTypes.string,
  perPeriodFee: PropTypes.number,
  perDayFee: PropTypes.number,
  vatPercent: PropTypes.number
};
