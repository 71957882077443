import {
  assignmentsActions,
  assignmentsSelectors,
  assignmentsThunks,
} from "../redux/userAssignments";
import { notifications, pending } from "../redux/common";
import { servicesThunks } from "../redux/services";
import { subscribedServicesThunks } from "../redux/subscribedServices";
import { subscriptionsThunks } from "../redux/subscriptions";

import ManageSubscribedService from "../components/SubscribedService";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  assignments: assignmentsSelectors.getAssignmentsAsArray(state),
  pending: pending.selectors.isPending(state),
});

// formik onSubmit handler
const assignUser = (values, subscribedServiceRef) => (dispatch, getState) =>
  dispatch(assignmentsThunks.postAddAssignment(values, subscribedServiceRef))
    .then((response) => {
      const oldState = assignmentsSelectors.getAssignments(getState());
      const assignments = { ...oldState, [response.data.ref]: response.data };
      dispatch(assignmentsActions.storeAssignments(assignments));
      dispatch(subscribedServicesThunks.getSubscribedServices());
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.status === 409 &&
        e.response.data.errorDescriptor.errorType ===
          "DUPLICATE_USER_ASSIGNMENT"
      ) {
        dispatch(
          notifications.actions.setNotification(
            { id: "manage_subscription.assign.error.duplicate" },
            "Error"
          )
        );
      } else {
        dispatch(
          notifications.actions.setNotification({ id: "a.api_error" }, "Error")
        );
      }
      throw e;
    });

const revokeUser =
  (subscribedServiceRef, assignmentRef) => (dispatch, getState) => {
    dispatch(
      assignmentsThunks.revokeAssignment(subscribedServiceRef, assignmentRef)
    ).then(() => {
      const { [assignmentRef]: removed, ...rest } =
        assignmentsSelectors.getAssignments(getState());
      dispatch(assignmentsActions.replaceAssignments(rest));
      dispatch(subscribedServicesThunks.getSubscribedServices());
    });
  };

export default connect(mapStateToProps, {
  ...servicesThunks,
  ...subscribedServicesThunks,
  ...subscriptionsThunks,
  ...assignmentsThunks,
  ...assignmentsActions,
  assignUser,
  revokeUser,
})(ManageSubscribedService);
