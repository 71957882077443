import "./ModifySubscribedService.scss";

import BackLink, { BackToAccount } from "../../components/BackLink";
import { ConfirmationDialog, Tags } from "..";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getLocationName,
  service2SubscribedServiceCard,
} from "../../lib/helpers";

import { LoggedInEnterpriseHeader } from "../../components/EnterpriseHeader";
import { NotificationTypes } from "../../constants";
import OrderSubscribedService from "../Purchase/OrderSubscribedService";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useService from "../../redux/useService";
import useSubscribedService from "../../redux/useSubscribedService";
import { useDispatch } from "react-redux";
import { servicesActions } from "../../redux/services";

const { View, LinkButton, FormButton } = Tags;

const ModifySubscribedService = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { subscribedServiceRef } = useParams();
  const subscribedService = useSubscribedService(subscribedServiceRef);
  const service = useService(subscribedService?.service?.ref);

  const [initialAmount, setInitialAmount] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (subscribedService) {
      const { subscriptionCount } = subscribedService;
      setInitialAmount(subscriptionCount);
      dispatch(
        servicesActions.changeOrder({
          key: subscribedService.service?.ref,
          value: subscriptionCount,
        })
      );
    }
  }, [dispatch, subscribedService]);

  const {
    increaseParkingRights,
    decreaseParkingRights,
    changeParkingRights,
    postPurchase,
    cancelSubscriptions,
    setNotification,
  } = props;

  const toggleConfirmDialog = () => {
    setShowConfirmation(!showConfirmation);
  };

  const purchase = (data) => {
    const { amount } = data.purchase;

    const difference = amount - initialAmount;

    if (difference > 0) {
      data.purchase.amount = difference;

      postPurchase(data)
        .then(
          setNotification(
            { id: "modifySubscribedService.increase.subscription.success" },
            NotificationTypes.Success
          )
        )
        .then(() => navigate(-1));
    }

    if (difference < 0) {
      cancelSubscriptions(Math.abs(difference), subscribedService.ref)
        .then(
          setNotification(
            { id: "modifySubscribedService.decrease.subscription.success" },
            NotificationTypes.Success
          )
        )
        .then(() => navigate(-1));
    }
  };

  const cancelSubscribedService = () => {
    const { setNotification } = props;
    // Just cancel all the subscriptions subscribedService has
    cancelSubscriptions(
      subscribedService.subscriptionCount,
      subscribedService.ref
    )
      .then(
        setNotification(
          { id: "modifySubscribedService.cancel.subscriptions.success" },
          NotificationTypes.Success
        )
      )
      .then(() => navigate(-1));
  };

  const checkDisabled = (data) => {
    if (data.purchase) {
      if (data.purchase.amount === initialAmount) {
        return "confirmation.no_changes";
      }
      if (
        data.purchase.amount >
        subscribedService.subscriptionCount + service.capacity
      ) {
        return "confirmation.over_capacity";
      }
    }
  };

  const changesMessage = (difference) => {
    if (difference === 0) {
      return <FormattedMessage id="purchase.difference.noChanges" />;
    }
    if (difference < 0) {
      return (
        <FormattedMessage
          id="purchase.difference.reduction"
          values={{ amount: difference * -1 }}
        />
      );
    }
    return (
      <FormattedMessage
        id="purchase.difference.increase"
        values={{ amount: difference }}
      />
    );
  };

  if (!service || !subscribedService) {
    return (
      <div>
        <LoggedInEnterpriseHeader />
        <View>
          <BackToAccount />
          Loading...
        </View>
      </div>
    );
  }
  const locations = service.locations
    ? service.locations.map(getLocationName).join(", ")
    : "";
  const data = service2SubscribedServiceCard(service);

  data.purchase.customHeader = intl.formatMessage({
    id: "modifySubscribedService.purchase.header",
  });
  const disabled = checkDisabled(data);
  const difference = data.purchase.amount - initialAmount;

  const orderComponentProps = {
    service,
    locations,
    data,
    increaseParkingRights,
    decreaseParkingRights,
    changeParkingRights,
    currentAmount: initialAmount,
    renderFormButtons: () => (
      <React.Fragment>
        <p className="ModificationChanges">{changesMessage(difference)}</p>
        <div className="ModifyButtons">
          <FormButton
            onClick={() => purchase(data)}
            disabled={!!disabled}
            title={disabled ? intl.formatMessage({ id: disabled }) : null}
          >
            <FormattedMessage id="modifySubscribedService.confirm_order" />
          </FormButton>
          <div>
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.84699 5L9.61748 2.22951C10.1275 1.71948 10.1275 0.892557 9.61748 0.382524C9.10744 -0.127508 8.28052 -0.127508 7.77049 0.382524L5 3.15301L2.22951 0.382524C1.71948 -0.127508 0.892557 -0.127508 0.382524 0.382524C-0.127508 0.892557 -0.127508 1.71948 0.382524 2.22951L3.15301 5L0.382524 7.77049C-0.127508 8.28052 -0.127508 9.10744 0.382524 9.61748C0.892557 10.1275 1.71948 10.1275 2.22951 9.61748L5 6.84699L7.77049 9.61748C8.28052 10.1275 9.10744 10.1275 9.61748 9.61748C10.1275 9.10744 10.1275 8.28052 9.61748 7.77049L6.84699 5Z"
                fill="#FF4848"
              />
            </svg>
            <LinkButton className="CancelButton" onClick={toggleConfirmDialog}>
              <FormattedMessage id="confirmation.cancel_order" />
            </LinkButton>
          </div>
        </div>
      </React.Fragment>
    ),
  };

  return (
    <div className="OrderConfirmation">
      <LoggedInEnterpriseHeader />
      <View>
        <BackLink to={`/managesubscription/${subscribedService.ref}`} />
        <OrderSubscribedService {...orderComponentProps} />
        <ConfirmationDialog
          isOpen={showConfirmation}
          message={intl.formatMessage({
            id: "modifySubscribedService.cancel.subscriptions.confirmation.message.all",
          })}
          onConfirm={cancelSubscribedService}
          onCancel={toggleConfirmDialog}
        >
          <p>
            <FormattedMessage id="modifySubscribedService.cancel.subscriptions.confirmation.info" />
          </p>
        </ConfirmationDialog>
      </View>
    </div>
  );
};

export default ModifySubscribedService;
