export const formatPrice = price => {
  const options = {
    useGrouping: false,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (Number.isFinite(price)) {
    return price.toLocaleString("fi", options);
  }

  const parsed = Number.parseFloat(price);
  return Number.isNaN(parsed) ? price : parsed.toLocaleString("fi", options);
};

export const calculateVatless = ({ pricePerMonth, 
  discountedTotalPrice, amount, vatPercent }) => {

  const withVat = discountedTotalPrice !== undefined ? discountedTotalPrice : pricePerMonth * amount;
  const vatAmount = withVat * vatPercent / (100 + vatPercent);
  const vatless = withVat - vatAmount;
  return vatless;
};