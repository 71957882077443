import "./VoucherCard.scss";

import Checkbox from "../Checkbox";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import React from "react";
import SVG from "../SVG";

const { LocationPin } = SVG;

const VoucherCard = ({ data, checked, onChange, children }) => {
  const { description, location, price } = data;
  return (
    <div className="VoucherCard">
      <div className="Container">
        <LocationPin className="LocationPin" />
        <p title={description} className="Title">
          {description}
        </p>

        <Checkbox label="" ariaLabel="" name={description} value={checked} onChange={onChange(data.ref)} />
        <div className="AfterTitle">
          <div>
            <span className="Emphasis">
              <FormattedMessage id="voucherCard.location" />
            </span>
            {location}
          </div>
          <div className="Price">
            <FormattedMessage
              id="voucherCard.price"
              values={{
                price: price.toLocaleString("fi", {
                  style: "currency",
                  currency: "EUR"
                })
              }}
            />
          </div>
        </div>
      </div>
        {children}
    </div>
  );
};

VoucherCard.propTypes = {
  data: PropTypes.shape({
    ref: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    cost: PropTypes.number
  }).isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

export default VoucherCard;
