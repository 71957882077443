import { servicesThunks } from "../redux/services";

import OrderConfirmation from "../components/Purchase/OrderConfirmation";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { purchasesThunks } from "../redux/purchases";
import { subscriptionsThunks } from "../redux/subscriptions";

export default compose(
  connect(null, {
    ...purchasesThunks,
    ...servicesThunks,
    ...subscriptionsThunks,
  }),
  injectIntl
)(OrderConfirmation);
