import PropTypes from "prop-types";
import { shortShape } from "./common";
import { locationShape } from "./location";

export const serviceShortShape = shortShape;

export const serviceMediumShape = {
  rev: PropTypes.number,
  ref: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string
};

export const serviceShape = {
  ...serviceMediumShape,
  canSubscribe: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.shape(locationShape)),
  subscriptionPlans: PropTypes.arrayOf(PropTypes.object), // obsolete data
  timezone: PropTypes.string,
  activeVehicleRequired: PropTypes.bool
};
