import { Checkbox } from "..";
import { withHandlers } from "recompose";

/**
 * For some reason, formik receives click events as if sent by svg that is enclosed inside
 * a button acting as a checkbox. So, re-inject the proper attributes to the event object
 * before passing it forward to formik
 */
const withFormikCheckboxAdapter = withHandlers({
  onChange: props => event => {
    event.target.type = "checkbox";
    event.target.name = props.name;
    event.target.checked = !props.value;
    props.onChange(event);
  }
});

export default withFormikCheckboxAdapter(Checkbox);