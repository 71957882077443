import { date } from "../lib";

export const signUpToInvoicing = signup => {
  const { firstName, familyName, email } = signup.contactPerson;
  const { address, postalCode, postOffice } = signup.company;
  return {
    type: "DIRECT",
    ovt: "",
    serviceId: "",
    firstName,
    lastName: familyName,
    companyName: signup.company.name,
    address,
    postalCode,
    postOffice,
    emailAddress: email
  };
};

export const accountToInvoicing = account => {
  return {
    type: account.billingMethod === "NONE" ? "DIRECT" : account.billingMethod,
    ovt: account.eInvoiceOvtNumber || "",
    serviceId: account.eInvoiceOperator || "",
    firstName: account.firstName,
    lastName: account.lastName,
    companyName: account.company,
    address: account.streetAddress,
    postalCode: account.postalCode,
    postOffice: account.city,
    emailAddress: account.email,
    customInvoiceReference: account.customInvoiceReference
  };
};

export const invoicingToAccount = values => {
  const { firstName, lastName, postalCode } = values;
  return {
    billingMethod: values.type,
    eInvoiceOvtNumber: values.ovt,
    eInvoiceOperator: values.serviceId,
    firstName,
    lastName,
    company: values.companyName,
    streetAddress: values.address,
    postalCode,
    city: values.postOffice,
    email: values.emailAddress,
    customInvoiceReference: values.customInvoiceReference
  };
};

const service2BaseSubscriptionCard = (service) => {
  const pricing = getPricing(service);
  const location =
    service.locations && service.locations.length
      ? service.locations[0]
      : { streetAddress: "", city: "" };
  return {
    title: service.name,
    publicService: service.publicService,
    address: location.name + ", " + location.city,
    pricingProfileInfo: pricing.pricingProfileInfo,
    hasDiscounts: doesServicehaveDiscounts(service)
  };
};

export const getPricing = service =>
  service.subscriptionPlans && service.subscriptionPlans.length
    ? service.subscriptionPlans[0]
    : { perPeriodFee: 0, vatAmount: 0 };

export const service2SubscribedServiceCard = service => {
  const pricing = getPricing(service);
  return {
    ref: service.ref,
    canSubscribe: service.canSubscribe,
    cannotSubscribeReason: service.cannotSubscribeReason,
    capacity: service.capacity,
    ...service2BaseSubscriptionCard(service),
    purchase: {
      amount: service._ui.count,
      pricePerMonth: pricing.perPeriodFee,
      discountedTotalPrice: service._ui.discountedPrice,
      vatPercent: pricing.vatPercent
    }
  };
};

const getStartDate = subscribedService => {
  const { startDate } = subscribedService;
  return startDate !== undefined
    ? date.parseISODate(startDate).toLocaleDateString("fi")
    : undefined;
};

const getAssignmentCount = subscribedService => {
  const { assignmentCount } = subscribedService;
  return assignmentCount !== undefined ? assignmentCount.toString() : undefined;
};

const getAssignmentsRemaining = subscribedService => {
  const { maxUserAssignments, assignmentCount } = subscribedService;
  return maxUserAssignments !== undefined && assignmentCount !== undefined
    ? (maxUserAssignments - assignmentCount >= 0 ? maxUserAssignments - assignmentCount : 0).toString()
    : undefined;
};

export const subscription2SubscriptionCard = (
  service,
  includePrice = false
) => ({
  ...service2BaseSubscriptionCard(service),
  subscription: {
    startDate: getStartDate(service),
    parkingCount: service.subscriptions.length,
    pricePerMonth: includePrice ? getPricing(service).perPeriodFee : undefined
  }
});

export const subscribedService2Card = (
  subscribedService,
  service,
  includePrice = false,
  subscriptionsEnding
) => {
  if (service) {
    const data = {
      ref: subscribedService.ref,
      capacity: service.capacity,
      ...service2BaseSubscriptionCard(service),
      subscribedService: {
        startDate: getStartDate(subscribedService),
        subscriptionCount: subscribedService.subscriptionCount.toString(),
        pricePerMonth: includePrice
          ? getPricing(service).perPeriodFee.toString()
          : undefined,
        assignmentCount: getAssignmentCount(subscribedService),
        assignmentsRemaining: getAssignmentsRemaining(subscribedService),
        subscriptionsEnding: subscriptionsEnding
          ? subscriptionsEnding
          : undefined
      }
    };
    return data;
  }
  return null;
};

export const getLocationName = (location) =>
location.name && location.city 
? `${location.name}, ${location.city}`
: `${location.name}${location.city}`;

export const doesServicehaveDiscounts = service =>
// lengthy check since there is no guarantee that all services have subscriptionPlans and pricingProfileInfo :(
service.subscriptionPlans && service.subscriptionPlans.length > 0 && 
service.subscriptionPlans[0].pricingProfileInfo && service.subscriptionPlans[0].pricingProfileInfo.length > 0;
    