import { FormattedMessage } from "react-intl";
import React from "react";
import { useDispatch } from "react-redux";
import { thunks } from "../../redux/keycloak";

const LogoutButton = () => {
  const dispatch = useDispatch();
  return (
    <div className="LogoutButton" onClick={() => dispatch(thunks.logout)}>
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.75 0.5H7.03125C7.28906 0.5 7.5 0.710938 7.5 0.96875V1.90625C7.5 2.16406 7.28906 2.375 7.03125 2.375H3.75C2.71484 2.375 1.875 3.21484 1.875 4.25V11.75C1.875 12.7852 2.71484 13.625 3.75 13.625H7.03125C7.28906 13.625 7.5 13.8359 7.5 14.0938V15.0312C7.5 15.2891 7.28906 15.5 7.03125 15.5H3.75C1.67969 15.5 0 13.8203 0 11.75V4.25C0 2.17969 1.67969 0.5 3.75 0.5ZM12.7773 1.26172L12.0117 2.02734C11.8242 2.21484 11.8281 2.51562 12.0195 2.69531L16.4375 6.98438H6.71875C6.46094 6.98438 6.25 7.19531 6.25 7.45312V8.54688C6.25 8.80469 6.46094 9.01562 6.71875 9.01562H16.4375L12.0195 13.3008C11.832 13.4844 11.8281 13.7852 12.0117 13.9688L12.7773 14.7344C12.9609 14.918 13.2578 14.918 13.4414 14.7344L19.8633 8.32812C20.0469 8.14453 20.0469 7.84766 19.8633 7.66406L13.4414 1.25781C13.2578 1.07813 12.9609 1.07812 12.7773 1.26172Z"
          fill="white"
        />
      </svg>
      <span>
        <FormattedMessage id="logout.logout" />
      </span>
    </div>
  );
};

export default LogoutButton;
