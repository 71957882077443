import { createAction, handleActions } from 'redux-actions';
import createBaseSelectors from './createBaseSelectors';
import { SUPPORTED_LOCALES } from '../localization';

const key = 'localization';

const SET_LOCALE = `${key}/SET_LOCALE`;

export const actions = {
  setLocale: createAction(SET_LOCALE)
};

const initialState = { locale: 'fi' };

export const reducer = {
  key,
  reducer: handleActions({
    [SET_LOCALE]: (state, action) => {
      const newLocale = action.payload;
      if (SUPPORTED_LOCALES.includes(newLocale)) {
        return { locale: newLocale };
      }
      return state;
    }
  }, initialState)
};

export const selectors = createBaseSelectors(key, initialState);
