import "./Table.scss";

import PropTypes from "prop-types";
import React from "react";

const Table = ({ headers, rows, renderRows }) => (
  <table className="MoovyTable">
    <tbody>
      {headers}
      {renderRows(rows)}
    </tbody>
  </table>
);

export const propTypes = {
  headers: PropTypes.node.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRows: PropTypes.func.isRequired,
};

Table.propTypes = propTypes;

export default Table;
