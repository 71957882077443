import { FormattedMessage, injectIntl } from "react-intl";

import React from "react";
import { Tags } from "..";
import { currency } from "../../lib";
import { doesServicehaveDiscounts } from "../../lib/helpers";

const { H1 } = Tags;

const getSubscriptionPlanDescriptions = plans =>
  plans
    .map(plan => plan.description)
    .map(description => ({ __html: description }))
    .map((markup, index) => (
      <div dangerouslySetInnerHTML={markup} key={index} />
    ));

const getPricingValues = pricing => {
  const { pricePerMonth, vatPercent } = pricing;

  const vatless = currency.calculateVatless({ ...pricing, amount: 1 });

  return {
    pricePerMonth: currency.formatPrice(pricePerMonth),
    vatPercent,
    vatless: currency.formatPrice(vatless)
  };
};

function OrderSubscribedServiceHeader({ service, locations, pricing, pricingProfileInfo, intl }) {
  return (
    <React.Fragment>
      <H1>{service.name}</H1>
      <p className="OrderSubscribedService__datarow">
        <span className="OrderSubscribedService__dataTitle">
          <FormattedMessage id="subscribedServiceCard.target" />
        </span>
        {locations}
      </p>
      <p className="OrderSubscribedService__datarow">
        <span className="OrderSubscribedService__dataTitle">
          <FormattedMessage id="subscribedServiceCard.capacity" />
        </span>
        {!!service.capacity || service.capacity === 0
          ? service.capacity
          : intl.formatMessage({
              id: "subscribedServiceCard.capacity.undefined"
            })}
      </p>
      <p className="OrderSubscribedService__datarow">
        <span className="OrderSubscribedService__dataTitle">
          <FormattedMessage id="confirmation.parkingright_start" />
        </span>
        <FormattedMessage id="confirmation.parkingright_start_value" />
      </p>
      <p className="OrderSubscribedService__datarow">
        <span className="OrderSubscribedService__dataTitle">
          <FormattedMessage id="confirmation.parkingright_end" />
        </span>
        <FormattedMessage id="confirmation.parkingright_end_value" />
      </p>
      {doesServicehaveDiscounts(service) ? null : (
        <p className="OrderSubscribedService__datarow">
        <span className="OrderSubscribedService__dataTitle">
          <FormattedMessage id="confirmation.price" />
        </span>
        <span>
          <FormattedMessage
            id="confirmation.price.value"
            values={getPricingValues(pricing)}
            />
        </span>
      </p>)
      }
      {pricingProfileInfo && pricingProfileInfo.length > 0 ?
          <div className="SubscribedServiceCard__ContentInner__subitems">
            <span className="SubscribedServiceCard__ContentInner__subitems__text">
              <FormattedMessage id="subscribedServiceCard.pricingProfile.info" />
            </span>
            <span>
                {pricingProfileInfo.join(', ')}
            </span>
          </div>
          : null
          }
      <div className="OrderSubscribedService__content__description">
        {service.description.split("\n").map((lineBreak, index) => (
          <p
            key={index}
            className="OrderSubscribedService__content__description__textblock"
          >
            {lineBreak}
          </p>
        ))}
        <div className="OrderSubscribedService__content__description__injected">
          {getSubscriptionPlanDescriptions(service.subscriptionPlans)}
        </div>
      </div>
    </React.Fragment>
  );
}

export default injectIntl(OrderSubscribedServiceHeader);
