/**
 * Finland-specific code bits.
 * Checking the business ID (Y-tunnus):
 * https://www.vero.fi/contentassets/38c6e70f10b342cdba4716e904c3edbf/tarkistusmerkin-laskenta.pdf
 */
const businessIdCheckUrl = "https://avoindata.prh.fi/tr/v1/";

const finnishBusinessIdRe = /^\d{7}-\d$/;

export const checkControlCharacter = businessId => {
  const id = businessId.substr(0, 7);
  const control = Number(businessId.charAt(8));
  const controlSum =
    Number(id.charAt(0)) * 7 +
    Number(id.charAt(1)) * 9 +
    Number(id.charAt(2)) * 10 +
    Number(id.charAt(3)) * 5 +
    Number(id.charAt(4)) * 8 +
    Number(id.charAt(5)) * 4 +
    Number(id.charAt(6)) * 2;
  const modus = controlSum % 11;
  const computedControl = modus === 0 ? 0 : 11 - modus;

  return control === computedControl && modus !== 1;
};

// validates form, but not legitimity (whether the company is actually real)
export const validForm = businessId =>
  typeof businessId === "string" &&
  finnishBusinessIdRe.test(businessId) &&
  checkControlCharacter(businessId);

// not enabled atm because the check is unreliable
export const checkLegitimity = businessId => {
  if (validForm(businessId)) {
    return fetch(`${businessIdCheckUrl}${businessId}`)
      .then(response => {
        return response.ok;
      })
      .catch(err => {
        return false;
      });
  }
  return Promise.resolve(false);
};

const postalCodeRe = /\d{5}/;
export const validPostalCode = code =>
  typeof code === "string" && code.length === 5 && postalCodeRe.test(code);

const finland = {
  validBusinessId: validForm,
  validPostalCode,
};
export default finland;
