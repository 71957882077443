import "./PurchaseControls.scss";

import Counter from "../Counter";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import React from "react";
import { currency } from "../../lib";

export const purchaseShape = PropTypes.shape({
  amount: PropTypes.number,
  pricePerMonth: PropTypes.number,
  vatPercent: PropTypes.number,
  customHeader: PropTypes.string
});

/* eslint react/no-multi-comp:0 */
const PurchaseControls = ({ purchase, onIncrease, onDecrease, onChange }) => {
  return (
    <div className="Purchase">
      <div className="Purchase__count">
        <p>
          {purchase.customHeader ? purchase.customHeader :
            <FormattedMessage id="purchase.count" />
          }
        </p>
        <Counter
          value={purchase.amount}
          onDecrease={onDecrease}
          onIncrease={onIncrease}
          onChange={onChange}
        />
      </div>
      <div className="Purchase__amount">
        <p>
          <FormattedMessage id="purchase.total" />
        </p>
        <p>
          <FormattedMessage
            id="purchase.amount"
            values={{ amount: currency.formatPrice(purchase.discountedTotalPrice !== undefined ? 
              purchase.discountedTotalPrice 
              : purchase.pricePerMonth * purchase.amount) }}
          />
        </p>
        <p>
          <FormattedMessage
            id="purchase.vat"
            values={{ vatPercent: purchase.vatPercent.toLocaleString("fi") }} />
        </p>
        <p>
          <FormattedMessage
            id="purchase.vatless"
            values={{ amount: currency.formatPrice(currency.calculateVatless(purchase)) }}
          />
        </p>

      </div>
    </div>
  );
};

PurchaseControls.propTypes = {
  purchase: purchaseShape.isRequired,
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

export default PurchaseControls;
