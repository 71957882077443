import { SpinnerBlock, Tags } from ".";
import { selectors as auth, thunks as keycloakThunks } from "../redux/keycloak";

import EnterpriseHeader from "./EnterpriseHeader";
import { FormattedMessage } from "react-intl";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors as localization } from "../redux/Localization";
import { servicesThunks } from "../redux/services";
import { subscribedServicesThunks } from "../redux/subscribedServices";

const RequireAuth = ({ children }) => {
  const [triedToFetchData, setTriedToFetchData] = useState(false);
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services);
  const subscribedServices = useSelector((state) => state.subscribedServices);
  const authenticated = useSelector(auth.getAuthenticated);
  const locale = useSelector(localization.getLocale);

  useEffect(() => {
    if (authenticated && !triedToFetchData) {
      dispatch(servicesThunks.getServices());
      dispatch(subscribedServicesThunks.getSubscribedServices());
      setTriedToFetchData(true);
    }
  }, [authenticated, dispatch, triedToFetchData]);

  if (!authenticated) {
    // if authenticated is null, we are waiting for keycloak lib to initialize
    if (authenticated !== null) {
      dispatch(
        keycloakThunks.login({
          redirectUrl: window.location.href,
          locale,
        })
      ); // redirects to keycloack
    } else {
      dispatch(keycloakThunks.initKeycloak());
    }
    return (
      <div>
        <EnterpriseHeader
          title={<FormattedMessage id="general.site.title" />}
        />
        <Tags.Page>
          <SpinnerBlock message={<FormattedMessage id="auth.waiting" />} />
        </Tags.Page>
      </div>
    );
  }

  return services && subscribedServices ? (
    children
  ) : (
    <div>
      <EnterpriseHeader title={<FormattedMessage id="general.site.title" />} />
      <Tags.Page></Tags.Page>
    </div>
  );
};

export default RequireAuth;
