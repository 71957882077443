import "./AccountSummary.scss";

import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import React from "react";
import { Tags } from "..";
import { enterpriseAccountShape } from "../../shapes";
import { useNavigate } from "react-router-dom";

const { LinkButton } = Tags;

const AccountSummary = ({ account }) => {
  const navigate = useNavigate();
  return (
    <div className="AccountSummary">
      <p className="AccountSummary__name">{account.company}</p>
      <p className="AccountSummary__businessID">{account.businessCode}</p>
      <p className="AccountSummary__address">
        <FormattedMessage
          id="account.address"
          values={{
            streetAddress: account.streetAddress,
            postalCode: account.postalCode,
            city: account.city,
          }}
        />
      </p>
      <p>
        <LinkButton onClick={() => navigate("/company")}>
          <FormattedMessage id="a.modify" />
        </LinkButton>
      </p>
      <table className="AccountSummary__table">
        <tbody>
          <tr>
            <th>
              <FormattedMessage id="account.contact_person" />
            </th>
            <td>
              <p>
                {account.firstName} {account.lastName}
              </p>
              <p>{account.email}</p>
              <p>{account.phone}</p>
              <p>
                <LinkButton onClick={() => navigate("/contactinfo")}>
                  <FormattedMessage id="a.modify" />
                </LinkButton>
              </p>
            </td>
          </tr>
          <tr>
            <th>
              <FormattedMessage id="account.invoicing" />
            </th>
            <td>
              <p>
                <FormattedMessage
                  id={`account.invoicing.type.${account.billingMethod}`}
                />
              </p>
              <p>
                <LinkButton onClick={() => navigate("/invoice")}>
                  <FormattedMessage id="a.modify" />
                </LinkButton>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

AccountSummary.propTypes = {
  account: PropTypes.shape(enterpriseAccountShape),
};

export default AccountSummary;
