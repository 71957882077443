/**
 * Formik-specific form block
 */

import FormBlock from "../FormBlock";
import { Input } from "..";
import React from "react";
import { Tags } from "..";
import { getError } from "../../lib/form";
import subformShape from "../../shapes/subformShape";

const { P } = Tags;
const CredentialsForm = ({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  intl,
  confirmPassword
}) => (
  <FormBlock titleId="wizard.userPass" key="CredentialsForm">
    <P>Valitse yrityksellesi käyttäjätunnus ja salasana.</P>
    <Input
      type="text"
      name="credentials.username"
      label={intl.formatMessage({
        id: "wizard.userPass.username.label"
      })}
      value={values.credentials.username}
      error={getError(touched, errors, "credentials.username")}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    <Input
      type="password"
      name="credentials.password"
      label={intl.formatMessage({
        id: "wizard.userPass.password.label"
      })}
      value={values.credentials.password}
      error={getError(touched, errors, "credentials.password")}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    {confirmPassword && (
      <Input
        type="password"
        name="credentials.confirmPassword"
        label={intl.formatMessage({
          id: "wizard.userPass.confirmPassword.label"
        })}
        value={values.credentials.confirmPassword}
        error={getError(touched, errors, "credentials.confirmPassword")}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    )}
  </FormBlock>
);

CredentialsForm.propTypes = subformShape;

export default CredentialsForm;
