import _ from "lodash";

/**
 * Utility to project fields from source object into another structure using the spec.
 * Spec is an object where keys are target keys and values are source keys. Keys can have
 * dots indicating nested objects. Ex:
 * project({ foo: 'bar.baz' }, { bar: { baz: 'yo, dude' } }) === { foo: 'yo, dude' }
 * This is used for example to make conversions between client and server representations of data.
 */

const project = (spec, source) => {
  if (
    typeof spec === "object" &&
    spec !== null &&
    typeof source === "object" &&
    source !== null
  ) {
    return Object.keys(spec).reduce(
      (acc, targetKey) =>
        _.set(
          acc,
          targetKey.split("."),
          _.get(source, spec[targetKey].split("."), null)
        ),
      {}
    );
  }
  return null;
};

export default project;