import "./BaseModal.scss";

import Modal from "react-modal";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const BaseModal = ({
  className,
  children,
  isOpen,
  onRequestClose,
  contentLabel,
  shouldCloseOnOverlayClick = true
}) => (
    <Modal
      overlayClassName="BaseModal__overlay"
      className={{
        base: classNames("BaseModal", className),
        afterOpen: "BaseModal--afterOpen",
        beforeClose: "BaseModal--beforeClose"
      }}
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={onRequestClose}
      closeTimeoutMS={300}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={false}
    >
      {children}
    </Modal>
  );

BaseModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  contentLabel: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired
};

BaseModal.defaultProps = { className: "" };

export default BaseModal;
