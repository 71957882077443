import "./Checkbox.scss";

import Circle from "./CheckCircle";
import PropTypes from "prop-types";
import React from "react";
import Tags from "../Tags";
import classNames from "classnames";

const { InvisibleButton } = Tags;

const Checkbox = ({
  label,
  ariaLabel,
  value,
  name,
  error,
  onChange,
  className
}) => (
  <div className={classNames("Checkbox", className)}>
    <InvisibleButton
      type="button"
      name={name}
      onClick={onChange}
      role="checkbox"
      aria-label={ariaLabel}
      value={!value}
    >
      <Circle checked={value} error={error} />
    </InvisibleButton>
    <span>{label}</span>
    <p className="Input__errormsg" title={error}>
      {error || ""}
    </p>
  </div>
);

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

Checkbox.defaultProps = {
  className: ""
};

export default Checkbox;
