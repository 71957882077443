import "./Input.scss";

import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const Input = ({
  className,
  readOnly,
  type,
  name,
  label,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  disabled,
  inputRef,
  min,
  max
}) => (
  <div className={classNames("Input", className)}>
    {label && <label htmlFor={name}>{label}</label>}
    <input
      type={type}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      readOnly={readOnly}
      disabled={disabled}
      min={min}
      max={max}
      className={classNames({
        Input__input: true,
        "Input__input--invalid": !!error
      })}
      aria-label={name}
      ref={inputRef}
    />
    <p className="Input__errormsg" title={error}>
      {error || ""}
    </p>
  </div>
);

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number
};

Input.defaultProps = {
  className: "",
  placeholder: "",
  value: undefined,
  error: "",
  onChange: () => {},
  onBlur: () => {},
  readOnly: false,
  disabled: false,
  min: undefined,
  max: undefined
};

export default Input;
