import * as yup from "yup";

import finland from "../../lib/finland";

export const companyTemplate = {
  company: {
    name: "",
    address: "",
    postalCode: "",
    postOffice: ""
  }
};

export const companySchemaObject = intl =>
  yup.object({ company: companySchema(intl) });

const companySchema = intl =>
  yup
    .object()
    .shape({
      name: yup
        .string()
        .required(intl.formatMessage({ id: "wizard.companyInfo.name.valid" }))
        .min(2, intl.formatMessage({ id: "wizard.companyInfo.name.valid" })),
      address: yup
        .string()
        .required(
          intl.formatMessage({ id: "wizard.companyInfo.address.valid" })
        )
        .min(3, intl.formatMessage({ id: "wizard.companyInfo.address.valid" })),
      postalCode: yup
        .string()
        .required(
          intl.formatMessage({ id: "wizard.companyInfo.postalCode.valid" })
        )
        .test(
          "postal-code",
          intl.formatMessage({ id: "wizard.companyInfo.postalCode.valid" }),
          finland.validPostalCode
        ),
      postOffice: yup
        .string()
        .required(
          intl.formatMessage({ id: "wizard.companyInfo.postOffice.valid" })
        )
        .min(
          2,
          intl.formatMessage({ id: "wizard.companyInfo.postOffice.valid" })
        )
    })
    .required();
