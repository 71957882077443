import "../styles/ManageContact.scss";

import {
  contactPersonSchemaObject,
  contactPersonTemplate,
} from "../schemas/ContactInfo.schema";

import { BackToAccount } from "../components/BackLink";
import ContactPerson from "../components/ContactPersonForm";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";
import { LoggedInEnterpriseHeader } from "../components/EnterpriseHeader";
import React from "react";
import { Tags } from "../components";
import useAccount from "../redux/useAccount";
import phoneNumbers from "../lib/phoneNumbers";
import { enterpriseAccountThunks } from "../redux/account";
import { useDispatch } from "react-redux";
import { notifications } from "../redux/common";
import { NotificationTypes } from "../constants";

const { View, FormButton, Page } = Tags;

const ManageContactInfo = () => {
  const account = useAccount();
  const intl = useIntl();
  const dispatch = useDispatch();

  // Don't want to set the form initial values before we have account, proper loading state would be better
  if (!account) {
    return null;
  }

  const formData = account
    ? {
        contactPerson: {
          firstName: account.firstName,
          familyName: account.lastName,
          email: account.email,
          phone: account.phone,
          promotionOptIn: account.emailMarketingAllowed,
        },
      }
    : { contactPerson: { ...contactPersonTemplate } };

  const onSubmit = (values, { setSubmitting }) => {
    const emailChanged = account.email !== values.contactPerson.email;
    setSubmitting(true);
    // Make sure that phone number is in international format
    values.contactPerson.phone = phoneNumbers.getInternationalPhoneNumber(
      values.contactPerson.phone
    );
    const updates = { contactPerson: { ...values.contactPerson } };

    dispatch(enterpriseAccountThunks.updateAccount(updates, true))
      .then(() => {
        dispatch(
          notifications.actions.setNotification(
            {
              id: emailChanged
                ? "manageContact.success.email"
                : "manageContact.success",
            },
            NotificationTypes.Success
          )
        );
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status === 409 &&
          err.response.data.message === "email already taken"
        ) {
          dispatch(
            notifications.actions.setNotification(
              { id: "manageContact.conflict.email" },
              NotificationTypes.Error
            )
          );
        } else {
          dispatch(
            notifications.actions.setNotification(
              { id: "a.api_error" },
              NotificationTypes.Error
            )
          );
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <LoggedInEnterpriseHeader
        title={<FormattedMessage id="wizard.title" />}
      />
      <Page>
        <View>
          <BackToAccount />
          <div className="container">
            <Formik
              initialValues={formData}
              onSubmit={onSubmit}
              validationSchema={contactPersonSchemaObject(intl)}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
                isSubmitting,
              }) => {
                return (
                  <form>
                    <ContactPerson
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      isValid={isValid}
                      intl={intl}
                    />
                    <FormButton
                      onClick={handleSubmit}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="manageContact.submit" />
                    </FormButton>
                  </form>
                );
              }}
            </Formik>
          </div>
        </View>
      </Page>
    </div>
  );
};

export default ManageContactInfo;
