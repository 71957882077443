import PropTypes from "prop-types";

const dateAmountShape = PropTypes.shape({
  date: PropTypes.objectOf(Date).isRequired,
  amount: PropTypes.number.isRequired
});

export const voucherCostsDataShape = {
  monthlyCustomerBenefits: PropTypes.arrayOf(dateAmountShape).isRequired,
  monthlyCosts: PropTypes.arrayOf(dateAmountShape).isRequired
};