import {
  servicesActions,
  servicesSelectors,
  servicesThunks,
  servicesUtils
} from "./services";

import { DataStoreTypes } from "../constants";
import { createAction } from "redux-actions";
import { doesServicehaveDiscounts } from "../lib/helpers";
import { mkDataStore } from "../redux";

const key = "subscriptions";
const STORE_BY_SUBSCRIBED_SERVICE = `${key}/STORE_BY_SUBSCRIBED_SERVICE`;

const reducerSeed = {
  [STORE_BY_SUBSCRIBED_SERVICE]: (state, action) => {
    const data = action.payload.data;
    const subscriptions = data.map(subscription => {
      const { subscribedService, ...without } = subscription;
      return without;
    });
    return { ...state, [action.payload.subscribedServiceRef]: subscriptions };
  }
};

const store = mkDataStore(key, DataStoreTypes.Keyed, { reducerSeed });
export const subscriptionsReducer = store.reducer;
export const subscriptionsActions = {
  ...store.actions,
  storeBySubscribedService: createAction(STORE_BY_SUBSCRIBED_SERVICE)
};
export const subscriptionsSelectors = store.selectors;

const getSubscriptions = subscribedServiceRef => (dispatch, getState, apis) =>
  apis.moovyApi.subscribedServices
    .getSubscriptions(dispatch, { subscribedServiceRef })
    .then(res => {
      res &&
        dispatch(
          subscriptionsActions.storeBySubscribedService({
            subscribedServiceRef,
            data: res.data
          })
        );
    });

    /**
 * Check whether more/less parking rights can be added to a service subscription.
 * This will need to access both service and subscription services. Implemented
 * as a thunk in order to get visibility to all of state.
 */
const fetchDiscountedPrice = (dispatch, service, count) => {
  if (doesServicehaveDiscounts(service)) {
    dispatch(servicesThunks.getDiscountedPrice(service.ref, count));
  }
};

// this one does not really require a thunk, is here for symmetry
const increaseParkingRights = serviceRef => (dispatch, getState) => {
  const state = getState();
  const services = servicesSelectors.getServices(state);
  const service = services[serviceRef];

  const maxCapacity = servicesUtils.getMaxCapacity(service);
  const currentCount = servicesUtils.getCount(service);

  // you can't order more than what is currently available
  if (currentCount + 1 <= maxCapacity) {
    dispatch(servicesActions.increaseOrder(serviceRef));
    fetchDiscountedPrice(dispatch, service, currentCount + 1);
  }
};

const decreaseParkingRights = serviceRef => (dispatch, getState) => {
  dispatch(servicesActions.decreaseOrder(serviceRef));
  const services = servicesSelectors.getServices(getState());
  const service = services[serviceRef];
  const currentCount = servicesUtils.getCount(service);
  fetchDiscountedPrice(dispatch, service, currentCount);
};

const changeParkingRights = (serviceRef, value) => (dispatch, getState) => {
  const checkedValue = value !== "" ? Number.parseInt(value, 10) : 0;

  if (!isNaN(checkedValue) && checkedValue >= 0) {
    dispatch(
      servicesActions.changeOrder({ key: serviceRef, value: checkedValue })
    );
    const services = servicesSelectors.getServices(getState());
    const service = services[serviceRef];
    fetchDiscountedPrice(dispatch, service, checkedValue);
  }
};

export const subscriptionsThunks = {
  getSubscriptions,
  increaseParkingRights,
  decreaseParkingRights,
  changeParkingRights
};
