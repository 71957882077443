import PropTypes from "prop-types";

const companyEditFormShape = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.shape({
    company: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      postalCode: PropTypes.string,
      postOffice: PropTypes.string
    })
  }).isRequired,
  errors: PropTypes.shape({
    company: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      postalCode: PropTypes.string,
      postOffice: PropTypes.string
    })
  }).isRequired,
  intl: PropTypes.object.isRequired
};

export default companyEditFormShape;