import "./Notification.scss";

import { FormattedMessage, injectIntl } from "react-intl";

import BaseModal from "../BaseModal";
import { NotificationTypes } from "../../constants/notification";
import PropTypes from "prop-types";
import React from "react";
import Tags from "../Tags";
import classNames from "classnames";

const Notification = ({ isOpen, type, children, onClose, intl }) => {
  const className = classNames({
    Notification: true,
    Notification__error: type === NotificationTypes.Error,
    Notification__success: type === NotificationTypes.Success
  });
  const trueType = NotificationTypes[type] ? type : "Notification";
  const typeLabel = `notification.contentLabel.${trueType}`;
  return (
    <BaseModal
      isOpen={isOpen}
      className={className}
      onRequestClose={onClose}
      contentLabel={intl.formatMessage({ id: typeLabel })}
    >
      <div className="Notification__content">
        <h4>
          <FormattedMessage id={typeLabel} />
        </h4>
        <p>{children}</p>
        <div className="Notification__buttonWrapper">
          <Tags.FormButton onClick={onClose} className="Notification__button">
            <FormattedMessage id="a.ok" />
          </Tags.FormButton>
        </div>
      </div>
    </BaseModal>
  );
};

Notification.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
};

Notification.defaultProps = { type: NotificationTypes.Notification };

export default injectIntl(Notification);
