import "./Separator.scss";

import React from "react";
import classNames from "classnames";

const Separator = ({ className = "" }) => (
  <div className={classNames("Separator", className)} />
);

export default Separator;
