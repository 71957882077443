/**
 * Formik-specific form block
 */

import {
  Input,
  Tags,
} from ".";

import FormBlock from "./FormBlock";
import { FormattedMessage } from "react-intl";
import FormikCheckbox from "./FormikCheckbox";
import React from "react";
import { getError } from "../lib/form";
import subformShape from "../shapes/subformShape";

const { FormText } = Tags;

const ContactPersonForm = ({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  intl
}) => (
  <FormBlock
    titleId="wizard.contactPerson"
    key="ContactPerson"
    smallTitle={true}
  >
    <div className="ContactInfo__doubleInput">
      <Input
        className="ContactInfo__doubleInput__left"
        type="text"
        name="contactPerson.firstName"
        label={intl.formatMessage({
          id: "wizard.contactPerson.firstName.label"
        })}
        value={values.contactPerson.firstName}
        error={getError(touched, errors, "contactPerson.firstName")}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Input
        className="ContactInfo__doubleInput__right"
        type="text"
        name="contactPerson.familyName"
        label={intl.formatMessage({
          id: "wizard.contactPerson.familyName.label"
        })}
        value={values.contactPerson.familyName}
        error={getError(touched, errors, "contactPerson.familyName")}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
    <Input
      type="text"
      name="contactPerson.email"
      label={intl.formatMessage({
        id: "wizard.contactPerson.email.label"
      })}
      value={values.contactPerson.email}
      error={getError(touched, errors, "contactPerson.email")}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    <Input
      type="text"
      name="contactPerson.phone"
      label={intl.formatMessage({
        id: "wizard.contactPerson.phone.label"
      })}
      value={values.contactPerson.phone}
      error={getError(touched, errors, "contactPerson.phone")}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    <FormText>
      <FormattedMessage id="wizard.marketingConsent" />
    </FormText>
    <FormikCheckbox
      label={<FormattedMessage id="wizard.communicationEmail" />}
      ariaLabel={intl.formatMessage({
        id: "wizard.communicationEmail"
      })}
      value={values.contactPerson.promotionOptIn}
      name="contactPerson.promotionOptIn"
      onChange={handleChange}
    />
  </FormBlock>
);

ContactPersonForm.propTypes = subformShape;

export default ContactPersonForm;
