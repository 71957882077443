import "./XXcrementor.scss";

import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const XXcrementor = ({ type = "incrementor", className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34 34"
    className={classNames("XXcrementor", className)}
  >
    <circle cx="17" cy="17" r="17" />
    <line x1="8" y1="17" x2="26" y2="17" />
    {type === "incrementor" && <line x1="17" y1="8" x2="17" y2="26" />}
  </svg>
);

XXcrementor.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string
};

export default XXcrementor;
