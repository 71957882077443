import { SpinnerBlock, Tags } from "../components";
import {
  enterpriseAccountSelector,
  enterpriseAccountThunks,
} from "../redux/account";

import MainPageContent from "../components/MainPage";
import { FormattedMessage } from "react-intl";
import { LoggedInEnterpriseHeader } from "../components/EnterpriseHeader";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const { Page } = Tags;

const MainPage = () => {
  const dispatch = useDispatch();
  const account = useSelector(enterpriseAccountSelector);
  useEffect(() => {
    if (!account) {
      dispatch(enterpriseAccountThunks.getAccount());
    }
  }, [account, dispatch]);

  return (
    <div>
      <LoggedInEnterpriseHeader />
      <Page>
        {account ? (
          <MainPageContent account={account} />
        ) : (
          <SpinnerBlock message={<FormattedMessage id="a.wait" />} />
        )}
      </Page>
    </div>
  );
};

export default MainPage;
