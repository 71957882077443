import React from "react";
import PropTypes from "prop-types";

const LocationPin = ({ className, type }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 35"
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={
          type === "gold"
            ? "url(#MoovyGradientYellow)"
            : "url(#MoovyGradientBlue)"
        }
        fillRule="nonzero"
        d="M0 15.217C0 6.813 6.716 0 15 0c8.284 0 15 6.813 15 15.217 0 6.978-4.629 12.858-10.94 14.654L15 35l-4.06-5.129C4.63 28.075 0 22.195 0 15.217z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M18.266 28.67l.422-.12c5.836-1.66 9.948-7.084 9.948-13.333C28.636 7.57 22.53 1.373 15 1.373c-7.529 0-13.636 6.196-13.636 13.844 0 6.249 4.112 11.673 9.948 13.333l.422.12L15 32.797l3.266-4.127zM0 15.217C0 6.813 6.716 0 15 0c8.284 0 15 6.813 15 15.217 0 6.978-4.629 12.858-10.94 14.654L15 35l-4.06-5.129C4.63 28.075 0 22.195 0 15.217z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M15 25.392c5.648 0 10.227-4.609 10.227-10.294 0-5.685-4.579-10.294-10.227-10.294-5.648 0-10.227 4.609-10.227 10.294 0 5.685 4.579 10.294 10.227 10.294z"
      />
      <g fill={type === "gold" ? "#FFC321" : "#0098D8"}>
        <path d="M11.534 19.043c-.994 0-1.802-.823-1.802-1.835 0-1.011.808-1.834 1.802-1.834.993 0 1.802.823 1.802 1.834 0 1.012-.809 1.835-1.802 1.835m0-5.124c-1.782 0-3.232 1.476-3.232 3.29 0 1.813 1.45 3.288 3.232 3.288 1.782 0 3.231-1.475 3.231-3.289 0-1.813-1.45-3.289-3.231-3.289M18.227 19.043c-.994 0-1.802-.823-1.802-1.835 0-1.011.808-1.834 1.802-1.834s1.802.823 1.802 1.834c0 1.012-.808 1.835-1.802 1.835m0-5.124c-1.782 0-3.232 1.476-3.232 3.29 0 1.813 1.45 3.288 3.232 3.288 1.782 0 3.231-1.475 3.231-3.289 0-1.813-1.45-3.289-3.231-3.289" />
        <path d="M21.492 12.394c-1.04-.823-2.255-1.108-3.207-1.188-.49-.809-1.048-1.387-1.699-1.76-.577-.33-1.22-.49-1.965-.483-2.277.017-3.807 1.093-4.142 2.855a5.346 5.346 0 0 0-1.924.793.779.779 0 0 0-.207 1.069.752.752 0 0 0 1.05.21c.831-.568 1.795-.623 1.8-.624a.764.764 0 0 0 .726-.752c.015-.663.251-1.15.724-1.487.47-.337 1.156-.517 1.984-.523.773-.005 1.678.205 2.538 1.816.13.242.375.395.646.402.767.02 1.866.191 2.747.888.33.261.805.2 1.061-.135a.78.78 0 0 0-.132-1.08" />
      </g>
    </g>
  </svg>
);

LocationPin.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string.isRequired
};

export default LocationPin;
