import { reducer as AuthReducer, getToken } from "./redux/keycloak";
import { applyMiddleware, compose, createStore } from "redux";
import { notifications, pending } from "./redux/common";

import { reducer as LocalizationReducer } from "./redux/Localization";
import { assignmentsReducer } from "./redux/userAssignments";
import { combineReducers } from "redux";
import { enterpriseAccountReducer } from "./redux/account";
import { isDevelopment } from "./lib/env";
import moovyApi from "./lib/moovyApi";
import { purchasesReducer } from "./redux/purchases";
import { servicesReducer } from "./redux/services";
import { signupParametersReducer } from "./redux/signup";
import { subscribedServicesReducer } from "./redux/subscribedServices";
import { subscriptionsReducer } from "./redux/subscriptions";
import thunkMiddleware from "redux-thunk";
import { voucherReducers } from "./redux/vouchers";

const rootReducer = combineReducers(
  [
    LocalizationReducer,
    pending.reducer,
    notifications.reducer,
    AuthReducer,
    enterpriseAccountReducer,
    subscribedServicesReducer,
    subscriptionsReducer,
    servicesReducer,
    purchasesReducer,
    assignmentsReducer,
    signupParametersReducer,
    ...voucherReducers
  ].reduce((reducers, reducerConf) => {
    const { key, reducer } = reducerConf;

    if (typeof reducer !== "function")
      throw new Error("reducer function missing");
    if (typeof key !== "string") throw new Error("reducer key missing");
    if (reducers[key]) throw new Error(`${key} is not unique`);

    reducers[key] = reducer;
    return reducers;
  }, {})
);

const configureStore = () => {
  const middlewares = [
    thunkMiddleware.withExtraArgument({
      moovyApi: moovyApi(getToken)
    })
  ];
  const enhancers = [applyMiddleware(...middlewares)];

  let composeEnhancers = compose;

  if (isDevelopment) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  return createStore(rootReducer, {}, composeEnhancers(...enhancers));
};

const store = configureStore();

export default store;
