/**
 * Creates reducer and actions for storing notifications
 */
import { createAction, handleActions } from "redux-actions";

import { NotificationTypes } from "../../constants/notification";
import { createSelector } from "reselect";

const mkNotifications = key => {
  const SET_NOTIFICATION = `${key}/SET_NOTIFICATION`;
  const CLEAR_NOTIFICATION = `${key}/CLEAR_NOTIFICATION`;

  const actions = {
    setNotification: createAction(SET_NOTIFICATION, (message, messageType) => ({
      message,
      type: messageType || NotificationTypes.Notification
    })),
    clearNotification: createAction(CLEAR_NOTIFICATION)
  };

  const reducer = {
    key,
    reducer: handleActions(
      {
        [SET_NOTIFICATION]: (state, action) => state.concat(action.payload),
        [CLEAR_NOTIFICATION]: state =>
          state.length > 1 ? state.slice(1, state.length) : [] // FIFO
      },
      []
    )
  };

  const rootSelector = state => state[key];

  const selectors = {
    isOpen: createSelector(rootSelector, n => n.length > 0),
    getMessage: createSelector(rootSelector, n => (n.length ? n[0] : {}))
  };

  return {
    actions,
    reducer,
    selectors
  };
};

export default mkNotifications;