import { fakeCostsData } from "./vouchersMock";
const endpoint = path => `/enterprise/v1${path}`;

const vouchers = ({ get, post }) => ({
  getVoucherTemplates: (params, opts) =>
    get(endpoint("/voucherTemplates"), params, opts),

  getUsedVouchers: (params, opts) =>
    get(endpoint("/grantedVouchersPagination"), params, opts),

  getOngoing: (params, opts) =>
    get(endpoint(`/voucherTemplates/${opts.templateRef}/ongoing`), params, opts),

  postGiveVoucher: (data, opts) => (
    post(
      endpoint(`/voucherTemplates/${opts.templateRef}/grantVoucher`),
      data,
      opts
    )
  ),

  getVoucherCosts: (params, opts) => (
    //get(endpoint(""), params, opts),
    Promise.resolve({ data: fakeCostsData })
  )
});

export default vouchers;