import { useSelector } from "react-redux";

const useSubscribedService = (subscribedServiceRef) => {
  return useSelector((state) => {
    if (state?.subscribedServices[subscribedServiceRef]) {
      return state.subscribedServices[subscribedServiceRef];
    }
    return undefined;
  });
};

export default useSubscribedService;
