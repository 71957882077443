import { AssignedUserStatus } from "../../constants";
import { FormattedMessage } from "react-intl";
import React from "react";
import { Tags } from "..";

const { LinkButton } = Tags;

const getUserStatus = user => {
  switch (user.status) {
    case AssignedUserStatus.PENDING:
      return (
        <div>
          <FormattedMessage id="manage_subscription.pending">
            {msg => <p className="ManageSubscription__attention">{msg}</p>}
          </FormattedMessage>
        </div>
      );
    case AssignedUserStatus.ACCEPTED:
      return <FormattedMessage id="manage_subscription.allocated" />;
    default:
      return <FormattedMessage id="manage_subscription.unknown" />;
  }
};

const AssignedUser = ({ subscribedServiceRef, user, revokeUser, onStartEdit, }) => (
  <tr>
    <td>{user.customName}</td>
    <td>{user.phoneNumber}</td>
    <td>{user.maxActiveVehicles}</td>
    <td>{user.additionalInfo}</td>
    <td>{getUserStatus(user)}</td>
    <td>
      <LinkButton onClick={() => revokeUser(subscribedServiceRef, user.ref)}>
        <FormattedMessage id="a.remove">{msg => `${msg} >`}</FormattedMessage>
      </LinkButton>
      <br />
      <LinkButton onClick={onStartEdit}>
        Muokkaa >
      </LinkButton>
    </td>
  </tr>
);

export default AssignedUser;
