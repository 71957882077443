import "./SendVoucher.scss";

import { FormattedMessage, injectIntl } from "react-intl";
import { Input, Tags } from "../..";
import React, { useEffect, useState } from "react";

import VoucherStartTimePicker from "./VoucherStartTimePicker";
import { formatIsoDate } from "../../../lib/dateTime";
import { grantTypes } from "..";

const VoucherSendingContainer = ({
  allowedGrantType,
  disabled,
  phoneNumber,
  onChangePhone,
  licensePlate,
  onChangeLicensePlate,
  ongoingParking,
  startTime,
  onChangeStartTime,
  expiration,
  onSend,
  intl,
}) => {
  const [grantMode, setGrantMode] = useState(grantTypes.PHONE);
  const grantingToPhone = grantMode === grantTypes.PHONE;

  useEffect(() => {
    if (allowedGrantType === grantTypes.PHONE) {
      setGrantMode(grantTypes.PHONE);
    } else if (allowedGrantType === grantTypes.LPN) {
      setGrantMode(grantTypes.LPN);
    }
  }, [allowedGrantType]);

  // If voucher cannot be granted, just return error message
  if (!allowedGrantType) {
    return (
      <div className="SecondaryContainer">
        <p>Virhe määrittelyissä, ota yhteyttä tukeen</p>
      </div>
    );
  }

  const renderTitles = () => {
    // Singular titles
    if (allowedGrantType !== grantTypes.BOTH) {
      const messageId =
        grantMode === grantTypes.PHONE
          ? "voucher.phone.title"
          : "voucher.licensePlate.only.title";
      return (
        <span className="Active">{intl.formatMessage({ id: messageId })}</span>
      );
    } else {
      // Titles when voucher can be granted to both phone and licenseplate

      // Helper function for rendering either a span or linkButton to switch granting mode
      const renderTextOrLink = (active, title, grantType) => {
        return active ? (
          <span className="Active">{title}</span>
        ) : (
          <Tags.LinkButton onClick={() => setGrantMode(grantType)}>
            {title}
          </Tags.LinkButton>
        );
      };

      return (
        <React.Fragment>
          {renderTextOrLink(
            grantingToPhone,
            intl.formatMessage({ id: "voucher.phone.title" }),
            grantTypes.PHONE
          )}

          <span className="Text">
            {intl.formatMessage({ id: "voucher.or" })}
          </span>

          {renderTextOrLink(
            !grantingToPhone,
            intl.formatMessage({ id: "voucher.licensePlate.title" }),
            grantTypes.LPN
          )}
        </React.Fragment>
      );
    }
  };

  return (
    <div className="SecondaryContainer">
      <React.Fragment>
        {renderTitles()}
        <div className="SendVoucherContainer">
          {grantingToPhone ? (
            <div>
              <span className="Label">
                {intl.formatMessage({ id: "voucher.phone.label" })}
              </span>
              <Input
                type="text"
                name="phone"
                value={phoneNumber}
                placeholder={intl.formatMessage({
                  id: "voucher.phone.placeholder",
                })}
                onChange={onChangePhone}
              />
            </div>
          ) : (
            <div>
              <span className="Label">
                {intl.formatMessage({ id: "voucher.licensePlate.label" })}
              </span>
              <Input
                type="text"
                name="licensePlate"
                value={licensePlate}
                placeholder={intl.formatMessage({
                  id: "voucher.licensePlate.placeholder",
                })}
                onChange={onChangeLicensePlate}
              />
            </div>
          )}
          {grantingToPhone ? (
            <div></div>
          ) : (
            <div>
              <span className="Label">
                <FormattedMessage id="voucher.licensePlate.parkingInformation" />
              </span>

              {ongoingParking.loading && <FormattedMessage id="a.wait" />}
              {ongoingParking.parking && (
                <div className="ParkingInformation">
                  <p>
                    {intl.formatMessage({
                      id: "voucher.licensePlate.parkingInformation.location",
                    })}
                    <span>{ongoingParking.parking.location.name}</span>
                  </p>
                  <p>
                    {intl.formatMessage({
                      id: "voucher.licensePlate.parkingInformation.started",
                    })}
                    <span>
                      {formatIsoDate(ongoingParking.parking.startTime, "fi")}
                    </span>
                  </p>
                </div>
              )}

              {!ongoingParking.parking && !ongoingParking.loading && (
                <p className="MarginTop">
                  {ongoingParking.init
                    ? intl.formatMessage({
                        id: "voucher.licensePlate.ongoing.notFound",
                      })
                    : intl.formatMessage({
                        id: "voucher.licensePlate.ongoing.placeHolder",
                      })}
                </p>
              )}
            </div>
          )}

          <div>
            <VoucherStartTimePicker
              customInput={<input className="Input__input" />}
              value={startTime}
              onChange={onChangeStartTime}
            />
          </div>
          <div>
            <span className="Label">
              {intl.formatMessage({ id: "voucher.expiration" })}
            </span>
            <p className="EvenMoreMarginTop">
              {formatIsoDate(expiration.toISOString(), "fi")}
            </p>
          </div>
        </div>
        <div className="SendButton">
          <Tags.FormButton onClick={onSend} disabled={disabled}>
            <FormattedMessage id="voucher.send.button" />
          </Tags.FormButton>
        </div>
      </React.Fragment>
    </div>
  );
};

export default injectIntl(VoucherSendingContainer);
