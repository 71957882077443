import LocationPin from "./LocationPin";
import SvgDefs from "./SvgDefs";
import XXcrementor from "./XXcrementor";

const SVG = {
  SvgDefs,
  LocationPin,
  XXcrementor
};

export default SVG;