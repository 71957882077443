import { augmentResponse, mkDataStore } from "../redux";

import { DataStoreTypes } from "../constants";

const store = mkDataStore("assignments", DataStoreTypes.Keyed);

export const assignmentsReducer = store.reducer;
export const assignmentsActions = store.actions;
export const assignmentsSelectors = store.selectors;

const server2client = augmentResponse(null);

const getAssignments = subscribedServiceRef => (dispatch, getState, apis) => {
  apis.moovyApi.assignments
    .getAssignments(dispatch, {}, { ref: subscribedServiceRef })
    .then(res => {
      res &&
        dispatch(assignmentsActions.storeAssignments(server2client(res.data)));
    });
};
const postAddAssignment = (data, subscribedServiceRef) => (
  dispatch,
  getState,
  apis
) =>
  apis.moovyApi.assignments.postAddAssignment(dispatch, data, {
    pathParams: { subscribedServiceRef },
    callerHandlesErrors: true
  });

const revokeAssignment = (subscribedServiceRef, assignmentRef) => (
  dispatch,
  getState,
  apis
) =>
  apis.moovyApi.assignments.postRevokeAssignment(
    dispatch,
    {},
    {
      pathParams: { subscribedServiceRef, assignmentRef }
    }
  );

const modifyAssignment = (data, subscribedServiceRef, assignmentRef) => (
  dispatch,
  getState,
  apis
) =>
  apis.moovyApi.assignments.putModifyAssignment(dispatch, data, {
    pathParams: { subscribedServiceRef, assignmentRef }
  });

export const assignmentsThunks = {
  getAssignments,
  postAddAssignment,
  modifyAssignment,
  revokeAssignment
};
