import { createAction, handleActions } from "redux-actions";

import { keycloak as Keycloak } from "../lib";

if (!(process.env.REACT_APP_AUTH_URL && process.env.REACT_APP_AUTH_CLIENT_ID)) {
  throw new Error("Bad configuration: missing keycloak URL and/or ID");
}

let keycloak = null;

/*
  React only cares if the user is authenticated or not.
*/
const key = "authenticated";

const SET_AUTH_STATUS = `${key}/SET_AUTH_STATUS`;

export const actions = {
  setAuthStatus: createAction(SET_AUTH_STATUS),
};

export const thunks = {
  // wrap keycloack actions as thunk
  initKeycloak:
    (opts = {}) =>
    (dispatch) => {
      if (keycloak) {
        return;
      }

      const keycloakOpts = {
        url: process.env.REACT_APP_AUTH_URL,
        realm: process.env.REACT_APP_AUTH_REALM,
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        ...opts,
      };

      const keycloakCallbacks = {
        onReady: (authenticated) =>
          dispatch(actions.setAuthStatus(authenticated)),
        onAuthSuccess: () => dispatch(actions.setAuthStatus(true)),
        onAuthError: () => dispatch(actions.setAuthStatus(false)),
        onAuthRefreshSuccess: () => dispatch(actions.setAuthStatus(true)),
        onAuthRefreshError: () => keycloak.logout(),
        onAuthLogout: () => dispatch(actions.setAuthStatus(false)),
      };

      keycloak = Keycloak.initializeKeyCloak({
        configuration: keycloakOpts,
        callbacks: keycloakCallbacks,
        updateInterval: 300,
      });
    },

  login: (opts) => () => keycloak.login(opts),

  logout: () => keycloak.logout(),
};

export const reducer = {
  key,
  reducer: handleActions(
    {
      [SET_AUTH_STATUS]: (_, action) => action.payload,
    },
    null
  ),
};

export const selectors = {
  getAuthenticated: (state) => state.authenticated,
};

export const getToken = () => keycloak && keycloak.token;
