/**
 * SVG one-time definitions for later reuse for SVG graphics.
 *
 * Include this once per project
 */
import React from "react";

const SvgDefs = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="0"
    height="0"
    style={{ position: "absolute" }}
  >
    <defs>
      <linearGradient
        id="MoovyGradientBlue"
        x1="0%"
        x2="99.797%"
        y1="0%"
        y2="99.797%"
      >
        <stop offset="0%" stopColor="#08CBEF" />
        <stop offset="100%" stopColor="#0098D8" />
      </linearGradient>
      <linearGradient
        id="MoovyGradientYellow"
        x1="0%"
        x2="99.797%"
        y1="0%"
        y2="99.797%"
      >
        <stop offset="0%" stopColor="#FDE43C" />
        <stop offset="100%" stopColor="#FFC321" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgDefs;
