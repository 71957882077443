import {
  DataStoreTypes,
  PurchaseItemType
} from "../constants";

import { mkDataStore } from "../redux";
import { v4 as uuidv4 } from 'uuid';

const store = mkDataStore("purchases", DataStoreTypes.Keyed);

export const purchasesReducer = store.reducer;
export const purchasesActions = store.actions;
export const purchasesSelectors = store.selectors;

const client2server = data => ({
  paymentCardCategory: 'BUSINESS', // Backend requires this, even though it has no relevancy to enterprise users
  invoiceMethod: 'INVOICE',
  items: [
    {
      type: PurchaseItemType.SERVICE_SUBSCRIPTION,
      ref: data.ref,
      quantity: data.purchase.amount
    }
  ],
  transactionId: uuidv4()
});

const postPurchase = data => (dispatch, getState, apis) =>
  apis.moovyApi.purchases
    .postPurchase(dispatch, client2server(data))
    .then(res => {
      return res
        ? dispatch(purchasesActions.storePurchases(res.data))
        : Promise.resolve();
    });

export const purchasesThunks = { postPurchase };
