import { FormattedMessage, useIntl } from "react-intl";
import {
  getLocationName,
  service2SubscribedServiceCard,
} from "../../lib/helpers";

import { BackToAccount } from "../../components/BackLink";
import { LoggedInEnterpriseHeader } from "../../components/EnterpriseHeader";
import OrderSubscribedService from "./OrderSubscribedService";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Tags } from "..";
import { useParams, useNavigate } from "react-router-dom";
import useService from "../../redux/useService";

const { View, FormButton } = Tags;

const getLocations = (locations) => locations.map(getLocationName).join(", ");

const OrderConfirmation = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();
  const { serviceRef } = useParams();

  const { increaseParkingRights, decreaseParkingRights, changeParkingRights } =
    props;

  const service = useService(serviceRef);

  const locations =
    service && service.locations ? getLocations(service.locations) : "";
  const data = service ? service2SubscribedServiceCard(service) : {};

  const disabled =
    isSubmitting ||
    (data.purchase &&
      (data.purchase.amount === 0 || data.purchase.amount > service.capacity));

  const disabledReason = () => {
    if (isSubmitting) {
      return "confirmation.submitting";
    } else if (data.purchase) {
      if (data.purchase.amount > service.capacity) {
        return "confirmation.over_capacity";
      } else if (data.purchase.amount === 0) {
        return "confirmation.zero_amount";
      }
      return "";
    }
  };

  const purchase = (data) => {
    setIsSubmitting(true);
    // account page re-loads subscriptions on mount
    props
      .postPurchase(data)
      .then((response) => {
        if (
          response.payload &&
          response.payload.items &&
          response.payload.items.length > 0
        ) {
          const subscribedService = response.payload.items[0].parent;
          navigate(`/managesubscription/${subscribedService.ref}`, {
            replace: true,
          });
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const orderComponentProps = {
    service,
    locations,
    data,
    increaseParkingRights,
    decreaseParkingRights,
    changeParkingRights,
    renderFormButtons: () => (
      <FormButton
        onClick={() => purchase(data)}
        disabled={disabled}
        title={disabled ? intl.formatMessage({ id: disabledReason() }) : null}
      >
        <FormattedMessage id="confirmation.confirm_order" />
      </FormButton>
    ),
  };

  return (
    <div className="OrderConfirmation">
      <LoggedInEnterpriseHeader />
      <View>
        <BackToAccount />
        {service && <OrderSubscribedService {...orderComponentProps} />}
      </View>
    </div>
  );
};

OrderConfirmation.propTypes = {
  increaseParkingRights: PropTypes.func.isRequired,
  decreaseParkingRights: PropTypes.func.isRequired,
  changeParkingRights: PropTypes.func.isRequired,
};

export default OrderConfirmation;
