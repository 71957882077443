import "./Footer.scss";

import { FormattedMessage, injectIntl } from "react-intl";

import AppStore from "./AppStore";
import GooglePlay from "./google-play-badge.png";
import React from "react";
import Tags from "../../components/Tags";

const View = Tags.View;
const A = Tags.A;

/* eslint react/no-multi-comp:0 */
const Address = () => (
  <p className="Footer__address">
    <FormattedMessage id="footer.address" />
  </p>
);

const Footer = ({ intl }) => {
  const phoneNumberForLink = "+358" + intl.formatMessage({ id: "footer.supportData.phone" }).substring(1).replace(/ /gi, '');
  return (
    <View className="Footer">
      <div className="Footer__info">
        <p className="Footer__support__title">
          <FormattedMessage id="footer.supportTitle" />
        </p>
        <p className="Footer__support__email">
          <A href={`mailto:${intl.formatMessage({ id: "footer.supportEmail" })}`}>
            <FormattedMessage id="footer.supportEmail" />
          </A>
        </p>
        <p>
          <FormattedMessage id="footer.supportData" />
          <A href={`tel:${phoneNumberForLink}`}>

            <FormattedMessage id="footer.supportData.phone" />
          </A>
        </p>
        <span className="Footer__select--desktop">
          <Address />
        </span>
      </div>
      <div className="Footer__apps">
        <p className="Footer__appslogan">
          <FormattedMessage id="footer.appSlogan" />
        </p>
        <div>
          <A href="https://apps.apple.com/app/apple-store/id1281465785">
            <AppStore className="Footer__apple" />
          </A>
          <A href="https://play.google.com/store/apps/details?id=fi.moovy.app">
            <img
              src={GooglePlay}
              alt="Get it on Google Play"
              className="Footer__google"
            />
          </A>
        </div>
      </div>
      <span className="Footer__select--mobile">
        <Address />
      </span>
    </View>
  );
};

export default injectIntl(Footer);
