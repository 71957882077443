const endpoint = path => `/enterprise/v1${path}`;

const enterprise = ({ post, get, put }) => ({
  createEnterpriseAccount: (data, customOpts) =>
    post(endpoint("/register"), data, customOpts),
  getEnterpriseAccount: (params, opts) => get(endpoint("/user"), params, opts),
  updateEnterpriseAccount: (data, customOpts) =>
    put(endpoint("/user"), data, customOpts)
});

export default enterprise;