import "./Tags.scss";

import PropTypes from "prop-types";
/**
 * Tags are html elements that have been enriched with css
 * 
 * Consider making actual named Components out of these.
 */
import React from "react";
import classNames from "classnames";

const elements = [
  { name: "View", node: "div" },
  { name: "H1", node: "h1" },
  { name: "H2", node: "h2" },
  { name: "FormText", node: "p" },
  { name: "InvisibleButton", node: "button" },
  { name: "LinkButton", node: "button" },
  { name: "FormButton", node: "button" },
  { name: "P", node: "p" },
  { name: "Page", node: "div" },
  { name: "A", node: "a" }
];

const mkElement = element => {
  const { name, node } = element;
  const Element = props => {
    const { className, children, ...rest } = props;
    return React.createElement(
      node,
      { className: classNames(name, className), ...rest },
      children
    );
  };

  Element.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
  };
  Element.defaultProps = { className: "" };

  return Element;
};

const Tags = elements.reduce(
  (tags, element) => ({
    ...tags,
    [element.name]: mkElement(element)
  }),
  {}
);

export default Tags;
