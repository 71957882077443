/**
 * Track ongoing async operations in state. Use to implement spinner(s).
 */
import { createAction, handleActions } from "redux-actions";

import { createSelector } from "reselect";

const mkTrackPending = key => {
  let id = 0;

  const getPendingId = () => {
    id += 1;
    return id;
  };

  const START_PENDING = `${key}/START_PENDING`;
  const END_PENDING = `${key}/END_PENDING`;

  const actions = {
    startPending: createAction(START_PENDING),
    endPending: createAction(END_PENDING)
  };

  const reducer = {
    key,
    reducer: handleActions(
      {
        [START_PENDING]: (state, action) => state.concat(action.payload),
        [END_PENDING]: (state, action) =>
          state.filter(i => i !== action.payload)
      },
      []
    )
  };

  const rootSelector = state => state[key];

  const selectors = {
    isPending: createSelector(rootSelector, p => p.length > 0)
  };

  return {
    getPendingId,
    actions,
    reducer,
    selectors
  };
};

export default mkTrackPending;