import "./InvoiceInfo.scss";

import { Checkbox, Input, Tags } from "..";
import {
  ELECTRIC,
  EMAIL,
  PAPER,
  schemas,
  template,
} from "./InvoiceInfo.schema";
import { FormattedMessage, useIntl } from "react-intl";

import { Formik } from "formik";
import React from "react";
import { getError } from "../../lib/form";

const { FormButton, H1, P } = Tags;

const renderElectric = ({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  intl,
}) => {
  return (
    <>
      <Input
        type="text"
        name="ovt"
        label={intl.formatMessage({
          id: "wizard.invoiceInfo.ovt.label",
        })}
        value={values.ovt}
        onChange={handleChange}
        onBlur={handleBlur}
        error={getError(touched, errors, "ovt")}
      />
      <Input
        type="text"
        label={intl.formatMessage({
          id: "wizard.invoiceInfo.serviceId.label",
        })}
        name="serviceId"
        value={values.serviceId}
        onChange={handleChange}
        onBlur={handleBlur}
        error={getError(touched, errors, "serviceId")}
      />
    </>
  );
};

const renderPaper = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  intl,
}) => {
  const { companyName, firstName, lastName, address, postalCode, postOffice } =
    values;
  return (
    <>
      <Input
        type="text"
        name="companyName"
        label={intl.formatMessage({
          id: "wizard.invoiceInfo.companyName.label",
        })}
        value={companyName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={getError(touched, errors, "companyName")}
      />
      <div className="InvoiceInfo__doubleInput">
        <Input
          className="InvoiceInfo__doubleInput__left"
          type="text"
          name="firstName"
          value={firstName}
          label={intl.formatMessage({
            id: "wizard.invoiceInfo.firstName.label",
          })}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError(touched, errors, "firstName")}
        />
        <Input
          className="InvoiceInfo__doubleInput__right"
          type="text"
          name="lastName"
          value={lastName}
          label={intl.formatMessage({
            id: "wizard.invoiceInfo.lastName.label",
          })}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError(touched, errors, "lastName")}
        />
      </div>
      <Input
        type="text"
        name="address"
        label={intl.formatMessage({
          id: "wizard.invoiceInfo.address.label",
        })}
        value={address}
        onChange={handleChange}
        onBlur={handleBlur}
        error={getError(touched, errors, "address")}
      />
      <div className="InvoiceInfo__doubleInput">
        <Input
          className="InvoiceInfo__doubleInput__left"
          type="text"
          name="postalCode"
          label={intl.formatMessage({
            id: "wizard.invoiceInfo.postalCode.label",
          })}
          value={postalCode}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError(touched, errors, "postalCode")}
        />
        <Input
          className="InvoiceInfo__doubleInput__right"
          type="text"
          name="postOffice"
          label={intl.formatMessage({
            id: "wizard.invoiceInfo.postOffice.label",
          })}
          value={postOffice}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError(touched, errors, "postOffice")}
        />
      </div>
    </>
  );
};

const renderEmail = ({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  intl,
}) => {
  return (
    <div className="emailInput">
      <Input
        type="text"
        name="emailAddress"
        value={values.emailAddress}
        label={intl.formatMessage({
          id: "wizard.invoiceInfo.emailAddress.label",
        })}
        onChange={handleChange}
        onBlur={handleBlur}
        error={getError(touched, errors, "emailAddress")}
      />
    </div>
  );
};

const changeType = (setFieldValue) => (ev) => {
  setFieldValue("type", ev.currentTarget.name);
};
const InvoiceInformationForm = ({ onSubmit, initialValues }) => {
  const intl = useIntl();

  return (
    <div className="InvoiceInfo__container">
      <div className="InvoiceInfo__column">
        <H1>
          <FormattedMessage id="wizard.invoiceInfo" />
        </H1>
        <P>
          <FormattedMessage id="wizard.invoiceInfo.instructions" />
        </P>
      </div>

      <Formik
        initialValues={template(initialValues)}
        onSubmit={onSubmit}
        validationSchema={schemas(intl)}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
        }) => (
          <form>
            <div className="InvoiceInfo__row">
              <div>
                <Checkbox
                  label={
                    <FormattedMessage id="wizard.invoiceInfo.type.digital" />
                  }
                  value={values.type === ELECTRIC}
                  onChange={changeType(setFieldValue)}
                  name={ELECTRIC}
                  ariaLabel={intl.formatMessage({
                    id: "wizard.invoiceInfo.type.digital",
                  })}
                />

                <Checkbox
                  label={
                    <FormattedMessage id="wizard.invoiceInfo.type.email" />
                  }
                  ariaLabel={intl.formatMessage({
                    id: "wizard.invoiceInfo.type.email",
                  })}
                  onChange={changeType(setFieldValue)}
                  value={values.type === EMAIL}
                  name={EMAIL}
                />
                <Checkbox
                  label={
                    <FormattedMessage id="wizard.invoiceInfo.type.paper" />
                  }
                  ariaLabel={intl.formatMessage({
                    id: "wizard.invoiceInfo.type.paper",
                  })}
                  onChange={changeType(setFieldValue)}
                  value={values.type === PAPER}
                  name={PAPER}
                />
              </div>
              <div>
                {values.type === ELECTRIC &&
                  renderElectric({
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    intl,
                  })}
                {values.type === PAPER &&
                  renderPaper({
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    intl,
                  })}
                {values.type === EMAIL &&
                  renderEmail({
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    intl,
                  })}
                <Input
                  type="text"
                  name="customInvoiceReference"
                  label={intl.formatMessage({
                    id: "wizard.invoiceInfo.customInvoiceReference.label",
                  })}
                  value={values.customInvoiceReference}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError(touched, errors, "customInvoiceReference")}
                />
              </div>
            </div>
            <div className="InvoiceInfo__centeredButton">
              <FormButton
                onClick={handleSubmit}
                disabled={isSubmitting}
                type="submit"
              >
                <FormattedMessage id="wizard.invoiceInfo.go" />
              </FormButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default InvoiceInformationForm;
export { ELECTRIC, PAPER, EMAIL };
