import PropTypes from "prop-types";

export const enterpriseAccountShape = {
  rev: PropTypes.number,
  ref: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string,
  streetAddress: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  locale: PropTypes.string,
  emailMarketingAllowed: PropTypes.bool,
  smsMarketingAllowed: PropTypes.bool,
  newEmailUnconfirmed: PropTypes.bool,
  role: PropTypes.string,
  businessCode: PropTypes.string
};
