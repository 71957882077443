import { Configuration, DataStoreTypes } from "../constants";
import { mkDataStore } from "../redux";

const voucherTemplateStore = mkDataStore(
  "voucherTemplates",
  DataStoreTypes.Keyed
);
const usedVoucherStore = mkDataStore("usedVouchers", DataStoreTypes.Keyed);
const voucherCostsStore = mkDataStore("voucherCosts", DataStoreTypes.Keyed);

const voucherTemplatesActions = voucherTemplateStore.actions;
const usedVouchersActions = usedVoucherStore.actions;

export const voucherReducers = [
  voucherTemplateStore.reducer,
  usedVoucherStore.reducer,
  voucherCostsStore.reducer,
];

const getVoucherTemplates = () => (dispatch, getState, apis) => {
  apis.moovyApi.vouchers.getVoucherTemplates(dispatch, {}).then((res) => {
    res && dispatch(voucherTemplatesActions.storeVoucherTemplates(res.data));
  });
};

const getUsedVouchers = (params) => (dispatch, getState, apis) => {
  apis.moovyApi.vouchers.getUsedVouchers(dispatch, params).then((res) => {
    res && dispatch(usedVouchersActions.storeUsedVouchers(res.data));
  });
};

const giveVoucher = (data, templateRef) => async (dispatch, getState, apis) => {
  const response = await apis.moovyApi.vouchers.postGiveVoucher(
    dispatch,
    data,
    {
      templateRef,
    }
  );
  const newData = [
    response.data,
    ...usedVoucherStore.selectors.getUsedVouchers(getState()).data,
  ];
  await dispatch(
    usedVoucherStore.actions.storeUsedVouchers({
      data: newData,
      totalResults: newData.length,
      totalPages: Math.ceil(
        newData.length / Configuration.USED_VOUCHERS_PAGESIZE
      ),
    })
  );
  return Promise.resolve();
};

const getOngoing =
  (templateRef, licensePlate) => async (dispatch, getState, apis) => {
    const response = await apis.moovyApi.vouchers.getOngoing(
      dispatch,
      { licensePlate },
      {
        templateRef,
      }
    );
    return Promise.resolve(response);
  };

const getVoucherCostData = (startDate) => (dispatch, getState, apis) => {
  apis.moovyApi.vouchers
    .getVoucherCosts(dispatch, { startDate })
    .then((res) => {
      res && dispatch(voucherCostsStore.actions.storeVoucherCosts(res.data));
    });
};

export const voucherThunks = {
  getVoucherTemplates,
  giveVoucher,
  getOngoing,
  getUsedVouchers,
  getVoucherCostData,
};

export const voucherSelectors = {
  ...voucherTemplateStore.selectors,
  ...usedVoucherStore.selectors,
  ...voucherCostsStore.selectors,
};

export const voucherActions = {
  ...voucherTemplatesActions,
  ...usedVouchersActions,
};
