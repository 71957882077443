import { augmentResponse, mkDataStore } from "../redux";

import { DataStoreTypes } from "../constants";

const store = mkDataStore("subscribedServices", DataStoreTypes.Keyed);

export const subscribedServicesReducer = store.reducer;
export const subscribedServicesActions = store.actions;
export const subscribedServicesSelectors = store.selectors;

const server2client = augmentResponse(null);

const getSubscribedServices = data => (dispatch, getState, apis) =>
  apis.moovyApi.subscribedServices.getSubscribedServices(dispatch).then(res => {
    const subscribedServicePromises = res.data.map(subscribedService =>
      apis.moovyApi.subscribedServices
        .getSubscribedServiceInfo(
          dispatch,
          {},
          { pathParams: { subscribedServiceRef: subscribedService.ref } }
        )
        .then(res => ({ ...subscribedService, ...res.data }))
    );

    Promise.all(subscribedServicePromises).then(subscribedServices => {
      dispatch(
        subscribedServicesActions.storeSubscribedServices(
          server2client(subscribedServices)
        )
      );
    });
  });

const updateSubscribedServiceInfo = subscribedServiceRef => (
  dispatch,
  getState,
  apis
) =>
  apis.moovyApi.subscribedServices
    .getSubscribedServiceInfo(
      dispatch,
      {},
      { pathParams: { subscribedServiceRef: subscribedServiceRef } }
    )
    .then(res => {
      let subscribedServices = subscribedServicesSelectors.getSubscribedServicesAsArray(
        getState()
      );
      const idx = subscribedServices.findIndex(
        ss => ss.ref === subscribedServiceRef
      );

      subscribedServices[idx] = { ...subscribedServices[idx], ...res.data };

      dispatch(
        subscribedServicesActions.replaceSubscribedServices(
          server2client(subscribedServices)
        )
      );
    });

const cancelSubscriptions = (amount, subscribedServiceRef) => (
  dispatch,
  getState,
  apis
) =>
  apis.moovyApi.subscribedServices.postCancelSubscriptions(
    dispatch,
    { amount },
    {
      pathParams: { subscribedServiceRef }
    }
  );

export const subscribedServicesThunks = {
  getSubscribedServices,
  updateSubscribedServiceInfo,
  cancelSubscriptions
};
