const endpoint = path => `/enterprise/v1/subscribedServices${path}`;

const subscribedServices = ({ get, post }) => ({
  getSubscribedServices: (params, opts) => get(endpoint(""), params, opts),
  getSubscribedServiceInfo: (params, opts) =>
    get(
      endpoint(`/${opts.pathParams.subscribedServiceRef}/subscriptionInfo`),
      params,
      opts
    ),
  postCancelSubscriptions: (data, opts) =>
    post(
      endpoint(`/${opts.pathParams.subscribedServiceRef}/subscriptions/end`),
      data,
      opts
    ),
  getSubscriptions: (params, opts) =>
    get(endpoint(`/${params.subscribedServiceRef}/subscriptions`), params, opts)
});

export default subscribedServices;