import "./EnterpriseHeader.scss";

import { FormattedMessage } from "react-intl";
import Navigation from "./HeaderNavigation";
import React from "react";
import { Tags } from "..";
import classNames from "classnames";
import logo1 from "./moovy-logo.png";
import logo2 from "./moovy-logo@2x.png";
import logo3 from "./moovy-logo@3x.png";
import { useNavigate } from "react-router-dom";

const EnterpriseHeader = ({ children, title }) => {
  return (
    <Tags.View className="Header">
      <div
        className={classNames({
          Header__facade: true,
          "Header__facade--margin": !!children,
        })}
      >
        <img
          className="Header__logo"
          src={logo1}
          srcSet={`${logo2} 2x, ${logo3} 3x`}
          alt="logo"
        />
        <h1 className="Header__title">{title}</h1>
      </div>
      <div className="Header__content">{children}</div>
    </Tags.View>
  );
};

export const LoggedInEnterpriseHeader = ({ children }) => {
  // Title for logged in users has a link to root
  const navigate = useNavigate();
  const title = (
    <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
      <FormattedMessage id="general.site.title" />
    </div>
  );

  return (
    <EnterpriseHeader title={title}>
      <Navigation />
      {children}
    </EnterpriseHeader>
  );
};

export default EnterpriseHeader;
