import PropTypes from "prop-types";
import { userShortShape } from "./user";

export const locationShape = {
  rev: PropTypes.number,
  ref: PropTypes.string,
  name: PropTypes.string,
  streetAddress: PropTypes.string,
  city: PropTypes.string,
  description: PropTypes.string,
  owner: PropTypes.shape(userShortShape),
  heating: PropTypes.bool,
  parkingControlled: PropTypes.bool,
  notHeatedParkingSpaces: PropTypes.number,
  heatedParkingSpaces: PropTypes.number,
  timezone: PropTypes.string,
  type: PropTypes.string,
  licencePlateRecognitionSupported: PropTypes.bool
};
