import Keycloak from "keycloak-js";

export function initializeKeyCloak({
  configuration,
  callbacks,
  updateInterval = 300
}) {
  const { onReady, onAuthSuccess, onAuthError, onAuthLogout } = callbacks;

  const keycloak = Keycloak(configuration);

  keycloak.onReady = onReady;
  keycloak.onAuthSuccess = onAuthSuccess;
  keycloak.onAuthError = onAuthError;
  keycloak.onAuthRefreshSuccess = onAuthSuccess;
  keycloak.onAuthRefreshError = onAuthError;
  keycloak.onAuthLogout = onAuthLogout;

  keycloak.init({ checkLoginIframe: false, onLoad: "check-sso" });

  setInterval(() => {
    if (keycloak.authenticated) {
      keycloak.updateToken(updateInterval);
    }
  }, updateInterval * 1000);

  return keycloak;
}
