import assignments from "./assignments";
import enterprise from "./enterprise";
import pendingRestCalls from "../pendingRestCalls";
import purchases from "./purchases";
import services from "./services";
import subscribedServices from "./subscribedServices";
import vouchers from "./vouchers";

const baseUrl = process.env.REACT_APP_API_URL;

const moovyApi = getToken =>
  pendingRestCalls({
    getToken,
    baseUrl,
    apiObject: {
      enterprise,
      subscribedServices,
      services,
      purchases,
      assignments,
      vouchers
    }
  });

export default moovyApi;