import "./index.scss";
import "./styles/reset.css";

import * as Sentry from "@sentry/react";

import App from "./App";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import { createRoot } from "react-dom/client";

const { REACT_APP_SENTRY_URL } = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_ENV,
  beforeSend(ev) {
    if (process.env.REACT_APP_ENV === "local-dev") {
      return null;
    }
    return ev;
  },
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
