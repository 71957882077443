import "./OrderSubscribedService.scss";

import React, { Component } from "react";
import { SVG, Separator } from "..";

import { FormattedMessage } from "react-intl";
import OrderSubscribedServiceHeader from "./OrderSubscribedServiceHeader";
import PropTypes from "prop-types";
import PurchaseControls from "../PurchaseControls";

const { LocationPin } = SVG;
export default class OrderSubscribedService extends Component {
  render() {
    const {
      service,
      locations,
      data,
      increaseParkingRights,
      decreaseParkingRights,
      changeParkingRights,
      renderFormButtons,
      currentAmount
    } = this.props;

    return (
      <div className="OrderSubscribedService__content">
        <div>
          <LocationPin
            className="OrderSubscribedService__content__icon"
            type={service.publicService ? "default" : "gold"}
          />
        </div>
        <div style={{ width: '1170px' }}>
          <OrderSubscribedServiceHeader
            service={service}
            locations={locations}
            pricing={data.purchase}
            pricingProfileInfo={data.pricingProfileInfo}
          />
          <Separator />
          <div className="OrderSubscribedService__content__purchase">
            {currentAmount &&
              <p className="current__amount">
                <FormattedMessage
                  id="modifySubscribedService.purchase.currentAmount"
                  values={{ currentAmount }} />
              </p>
            }
            <PurchaseControls
              purchase={data.purchase}
              onIncrease={() => increaseParkingRights(service.ref)}
              onDecrease={() => decreaseParkingRights(service.ref)}
              onChange={evt => changeParkingRights(data.ref, evt.target.value)}
            />

            {renderFormButtons()}
          </div>
        </div>
      </div>
    );
  }
}

OrderSubscribedService.propTypes = {
  service: PropTypes.shape(),
  locations: PropTypes.string,
  data: PropTypes.shape(),
  increaseParkingRights: PropTypes.func.isRequired,
  decreaseParkingRights: PropTypes.func.isRequired,
  changeParkingRights: PropTypes.func.isRequired,
  renderFormButtons: PropTypes.func.isRequired
};
