import {
  enterpriseAccountSelector,
  enterpriseAccountThunks,
} from "../redux/account";
import { voucherSelectors, voucherThunks } from "../redux/vouchers";

import Voucher from "../components/Voucher";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

const mapStateToProps = (state) => ({
  account: enterpriseAccountSelector(state),
  voucherTemplates: voucherSelectors.getVoucherTemplatesAsArray(state),
  usedVouchers: voucherSelectors.getUsedVouchers(state),
  voucherCosts: voucherSelectors.getVoucherCosts(state),
});

export default compose(
  connect(mapStateToProps, {
    ...enterpriseAccountThunks,
    ...voucherThunks,
  }),
  injectIntl
)(Voucher);
