import "./styles.scss";

import { SubscribedServiceCard, Tags } from "..";
import {
  doesServicehaveDiscounts,
  subscribedService2Card,
} from "../../lib/helpers";

import AssignmentsTable from "../UserAssignment/AssignmentsTable";
import { BackToAccount } from "../../components/BackLink";
import { FormattedMessage } from "react-intl";
import { LoggedInEnterpriseHeader } from "../../components/EnterpriseHeader";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { date } from "../../lib";
import { useNavigate } from "react-router-dom";
import useSubscriptions from "../../redux/useSubscriptions";
import useService from "../../redux/useService";
import { useParams } from "react-router-dom";
import useSubscribedService from "../../redux/useSubscribedService";

const { View, Page, H1 } = Tags;

const createEndingSubscriptionsForCard = (subscriptions) => {
  return subscriptions.reduce((acc, current) => {
    if (current.endDate) {
      const formattedDate = date
        .parseISODate(current.endDate)
        .toLocaleDateString("fi");
      let endedOnDate = acc.find((ended) => ended.date === formattedDate);
      if (endedOnDate) {
        endedOnDate.count += 1;
      } else {
        endedOnDate = { date: formattedDate, count: 1 };
        acc.push(endedOnDate);
      }
    }
    return acc;
  }, []);
};

const ManageSubscribedService = (props) => {
  const unassignedNameRef = useRef();
  const { subscribedServiceRef } = useParams();
  const subscribedService = useSubscribedService(subscribedServiceRef);
  const service = useService(subscribedService?.service?.ref);
  const subscriptions = useSubscriptions(subscribedServiceRef);
  const navigate = useNavigate();

  const {
    assignments,
    assignUser,
    revokeUser,
    pending,
    eraseAssignments,
    getSubscribedServices,
    getAssignments,
    getSubscriptions,
  } = props;

  useEffect(() => {
    eraseAssignments();
    getSubscribedServices();
    getAssignments(subscribedServiceRef);
    getSubscriptions(subscribedServiceRef);
  }, [
    eraseAssignments,
    getAssignments,
    getSubscribedServices,
    getSubscriptions,
    subscribedServiceRef,
  ]);

  const subscriptionsEnding = subscriptions
    ? createEndingSubscriptionsForCard(subscriptions)
    : undefined;

  const cardData = subscribedService
    ? subscribedService2Card(
        subscribedService,
        service,
        !doesServicehaveDiscounts(service),
        subscriptionsEnding
      )
    : {};

  const getAssignmentsRemaining = (subscribedService) =>
    subscribedService
      ? subscribedService.maxUserAssignments - subscribedService.assignmentCount
      : 0;

  return (
    <div>
      <LoggedInEnterpriseHeader />
      <Page>
        <View className="ManageSubscription">
          <BackToAccount />
          <H1>
            <FormattedMessage id="manage_subscription.title" />
          </H1>
          {service && (
            <div className="ManageSubscription__content">
              <SubscribedServiceCard
                data={cardData}
                action={() =>
                  navigate(`/modifysubscribedservice/${cardData.ref}`)
                }
                actionLabel={
                  <FormattedMessage id="manage_subscription.modify_or_cancel" />
                }
                actionIsALink
                buttonLess
                theme={cardData.publicService ? "" : "gold"}
              />
              <AssignmentsTable
                subscribedServiceRef={subscribedService.ref}
                assignments={assignments}
                assignUser={assignUser}
                revokeUser={revokeUser}
                pending={pending}
                assignmentsRemaining={getAssignmentsRemaining(
                  subscribedService
                )}
                unassignedNameRef={unassignedNameRef}
              />
            </div>
          )}
        </View>
      </Page>
    </div>
  );
};

ManageSubscribedService.propTypes = {
  assignUser: PropTypes.func.isRequired,
  revokeUser: PropTypes.func.isRequired,
};

export default ManageSubscribedService;
