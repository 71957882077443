import en from "./en.json";
import fi from "./fi.json";
import { localization } from "../lib";

export const SUPPORTED_LOCALES = ["fi", "en"];

const locales = {
  fi: { ...fi, ...localization.messages.fi },
  en: { ...en, ...localization.messages.en }
};

export default locales;