import {
  companySchemaObject,
  companyTemplate,
} from "../components/ManageCompany/ExistingCompany.schema";

import { BackToAccount } from "../components/BackLink";
import ExistingCompany from "../components/ManageCompany/ExistingCompany";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";
import { LoggedInEnterpriseHeader } from "../components/EnterpriseHeader";
import React from "react";
import { Tags } from "../components";
import { useDispatch } from "react-redux";
import { enterpriseAccountThunks } from "../redux/account";
import { notifications } from "../redux/common";
import { NotificationTypes } from "../constants";
import useAccount from "../redux/useAccount";

const { FormButton, Page, View } = Tags;

const ManageCompany = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const account = useAccount();

  // Don't want to set the form initial values before we have account, proper loading state would be better
  if (!account) {
    return null;
  }

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const updates = { ...values };
    dispatch(enterpriseAccountThunks.updateCompany(updates))
      .then(() => {
        dispatch(
          notifications.actions.setNotification(
            { id: "manageContact.success" },
            NotificationTypes.Success
          )
        );
      })
      .finally(() => setSubmitting(false));
  };

  const formData = account
    ? {
        company: {
          name: account.company,
          address: account.streetAddress,
          postalCode: account.postalCode,
          postOffice: account.city,
        },
      }
    : { company: { ...companyTemplate } };

  return (
    <div>
      <LoggedInEnterpriseHeader
        title={<FormattedMessage id="wizard.title" />}
      />
      <Page>
        <View>
          <BackToAccount />
          <div className="container">
            <Formik
              initialValues={formData}
              onSubmit={onSubmit}
              validationSchema={companySchemaObject(intl)}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
                isSubmitting,
              }) => {
                return (
                  <form>
                    <ExistingCompany
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      isValid={isValid}
                      intl={intl}
                    />
                    <FormButton
                      onClick={handleSubmit}
                      type="submit"
                      disabled={isSubmitting || !isValid}
                    >
                      <FormattedMessage id="manageContact.submit" />
                    </FormButton>
                  </form>
                );
              }}
            </Formik>
          </div>
        </View>
      </Page>
    </div>
  );
};

export default ManageCompany;
