import ErrorBoundary from './components/ErrorBoundary';
import { IntlProvider } from "react-intl";
import Notifications from "./components/Notifications";
import { Provider } from "react-redux";
import Routes from "./Routes";
import { SVG } from "./components";
import messages from "./localization";
import { selectors as s } from "./redux/Localization";
import store from "./store";

function App() {
  const locale = s.getLocale(store.getState());
  return (
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages[locale]} key={locale}>
        <ErrorBoundary>
          <div className="App">
              <SVG.SvgDefs />
              <Routes />
              <Notifications />
          </div>
        </ErrorBoundary>
        </IntlProvider>
      </Provider>
    );
}

export default App;
