/**
 * Form helpers
 */
import { get } from "lodash";

// our input component expects an error string, but is agnostic towards formik.
// this method extracts an error from a formik errors object
export const getError = (touched, errors, path) => {
  const aPath = path.split(".");
  return get(touched, aPath, null) !== null ? get(errors, aPath) : null;
};
