import { FormattedMessage, injectIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import { Separator, SubscribedServiceCard, Tags } from "..";
import {
  doesServicehaveDiscounts,
  service2SubscribedServiceCard,
} from "../../lib/helpers";
import { useDispatch, useSelector } from "react-redux";

import { getUnsubscribedServices } from "../../redux/account";
import { subscriptionsThunks } from "../../redux/subscriptions";
import { useNavigate } from "react-router-dom";

const { H1 } = Tags;

const getServicesByCity = (services) => {
  const publicByCity = services.reduce((acc, service) => {
    if (service.locations && service.locations.length > 0) {
      const city = service.locations[0].city;

      const grouping = acc[city];
      if (!grouping) {
        acc[city] = [service];
      } else {
        acc[city] = [...grouping, service];
      }
    }
    return acc;
  }, {});
  return publicByCity;
};

const ProductFilters = ({ services, menuItems, selectedCity, onChange }) => {
  if (!services || services.length === 0) {
    return null;
  }

  return (
    <ul id="Account__public_services_menu">
      <li>
        <FormattedMessage id="account.public_services.menu" />
      </li>

      {menuItems.map((item, index, array) => (
        <li key={item.key} className="Account__public_services_menu_item">
          {selectedCity !== item.key ? (
            <span
              className="Account__public_services_menu_link"
              onClick={() => onChange(item.key)}
            >
              {item.label}
            </span>
          ) : (
            <span>{item.label}</span>
          )}
          {index + 1 < array.length && " | "}
        </li>
      ))}
    </ul>
  );
};

const ProductOffering = (props) => {
  const { intl, account } = props;

  const [selectedCity, setSelectedCity] = useState("all");
  const unSubscribedServices = useSelector(getUnsubscribedServices);

  // Filter out ones that cant be invoiced
  const canbeInvoiced = unSubscribedServices.filter(
    (service) =>
      service.cannotSubscribeReason !== "NOT_INVOICABLE_FOR_ENTERPRISE_USER"
  );
  const privateServices = canbeInvoiced.filter(
    (service) => !service.publicService || doesServicehaveDiscounts(service)
  );

  const publicServices = canbeInvoiced.filter(
    (service) =>
      service.publicService &&
      !privateServices.find((ps) => service.ref === ps.ref)
  );

  const publicMenuItems = [
    {
      key: "all",
      label: intl.formatMessage({ id: "account.public_services.menu.all" }),
    },
  ];

  const publicByCity = getServicesByCity(publicServices);

  useEffect(() => {
    if (account && account.city) {
      setSelectedCity(account.city);
    }
  }, [account]);

  publicMenuItems.push(
    ...Object.keys(publicByCity).map((city) => {
      return { key: city, label: city };
    })
  );
  let filteredPublicServices = publicServices;

  if (selectedCity !== "all") {
    const byCity = publicByCity[selectedCity];
    if (byCity) {
      filteredPublicServices = byCity;
    }
  }

  return (
    <React.Fragment>
      {privateServices && privateServices.length > 0 && (
        <React.Fragment>
          <Separator />
          <div className="Account__prospects">
            <H1 className="Account__subtitle">
              <FormattedMessage id="account.private_services" />
            </H1>
            <div className="Account__serviceList">
              <ServiceCards
                services={privateServices}
                theme="gold"
                intl={intl}
              />
            </div>
          </div>
        </React.Fragment>
      )}

      <Separator />
      <div className="Account__prospects">
        <H1 className="Account__subtitle">
          <FormattedMessage id="account.public_services" />
        </H1>
        <ProductFilters
          services={filteredPublicServices}
          menuItems={publicMenuItems}
          selectedCity={selectedCity}
          onChange={(value) => setSelectedCity(value)}
        />
        <div className="Account__serviceList">
          <ServiceCards services={filteredPublicServices} intl={intl} />
        </div>
      </div>
    </React.Fragment>
  );
};

function ServiceCards({ services, theme, intl }) {
  const { increaseParkingRights, decreaseParkingRights, changeParkingRights } =
    subscriptionsThunks;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return services
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((service) => service2SubscribedServiceCard(service))
    .map((data) => {
      const { canSubscribe } = data;

      let disabledReason = "";
      if (!canSubscribe) {
        disabledReason = intl.formatMessage({
          id: "purchase.disabled_reason_no_capacity",
        });
      } else if (data.purchase.amount > data.capacity) {
        disabledReason = intl.formatMessage({
          id: "purchase.disabled_reason_over_capacity",
        });
      }
      return (
        <SubscribedServiceCard
          key={data.ref}
          data={data}
          actionLabel={
            <FormattedMessage id="subscribedServiceCard.subscribe" />
          }
          action={() => {
            navigate(`/orderconfirmation/${data.ref}`);
          }}
          onIncrease={() => dispatch(increaseParkingRights(data.ref))}
          onDecrease={() => dispatch(decreaseParkingRights(data.ref))}
          onChange={(evt) =>
            dispatch(changeParkingRights(data.ref, evt.target.value))
          }
          disabled={!data.canSubscribe || data.purchase.amount > data.capacity}
          title={disabledReason}
          theme={theme || ""}
        />
      );
    });
}
export default injectIntl(ProductOffering);
