import "./Pagination.scss";

import PropTypes from "prop-types";
import React from "react";

export default function Pagination({
  pageSize,
  currentPage,
  totalResults,
  changePage
}) {
  const pageCount = Math.ceil(totalResults / pageSize);

  if (pageCount <= 1) {
    return null;
  }

  const hasNextPage = currentPage + 1 < pageCount;
  const hasPreviousPage = currentPage > 0;

  const renderBackLinks = () => {
    const className = hasPreviousPage ? "ChangePage" : "Disabled";
    return (
      <React.Fragment>
        <span
          className={className}
          onClick={() => changePage(0)}
        >
          &lt;&lt;
      </span>
        <span
          className={className}
          onClick={() => changePage(hasPreviousPage ? currentPage - 1 : 0)}
        >
          &lt;
      </span>
      </React.Fragment>
    );
  };

  const renderNextLinks = () => {
    const className = hasNextPage ? "ChangePage" : "Disabled";
    return (
      <React.Fragment>
        <span
          className={className}
          onClick={() => changePage(hasNextPage ? currentPage + 1 : currentPage)}
        >
          &gt;
      </span>
        <span
          className={className}
          onClick={() => changePage(hasNextPage ? pageCount - 1 : currentPage)}
        >
          &gt;&gt;
      </span>
      </React.Fragment>
    );
  };

  const renderPageNumbers = () => {
    const pageChanges = [];

    const maxItems = pageCount < 7 ? pageCount : 7;
    for (let i = 0; i < maxItems; i++) {
      pageChanges.push(i);
    }
    if (maxItems === 7) {
      pageChanges[6] = pageCount - 1;

      const cutBeginning = currentPage >= 4;
      const cutEnd = currentPage < pageCount - 4;

      if (cutBeginning) {
        pageChanges[1] = '.';
      } else {
        pageChanges[1] = 1;
        pageChanges[2] = 2;
        pageChanges[3] = 3;
      }

      if (cutEnd) {
        pageChanges[5] = '.';
      } else {
        pageChanges[2] = pageCount - 5;
        pageChanges[3] = pageCount - 4;
        pageChanges[4] = pageCount - 3;
        pageChanges[5] = pageCount - 2;
      }

      if (cutBeginning && cutEnd) {
        pageChanges[2] = currentPage - 1;
        pageChanges[3] = currentPage;
        pageChanges[4] = currentPage + 1;
      }
    }

    return (
      <React.Fragment>
        {pageChanges.map((pageChange, i) => {
          const isCurrentPage = currentPage === pageChange;
          const pageText = pageChange + 1;
          if (isCurrentPage) {
            return <span key={i} className="CurrentPageText">{pageText}</span>;
          }
          if (Number.isFinite(pageChange)) {
            return <span key={i} className="ChangePage" onClick={() => changePage(pageChange)}>
              {pageText}
            </span>;
          }
          return <span key={i} className="dots">...</span>;
        })}
      </React.Fragment>
    );
  };

  return (
    <div className="Pagination">
      {renderBackLinks()}
      {renderPageNumbers()}
      {renderNextLinks()}

    </div>
  );
}

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired
};
