/**
 * Create reducer and action to handle data of various types. Two modes will be supported,
 * storage with keys, where data is stored under keys:
 * { key1: data1, key2: data2, ...},
 * this is for cases where you want to store objects identified by some reference (like
 * individual subscriptions or the like).
 * In a keyless mode, only one piece of data is held in the store. For cases where you
 * store one unique piece of data, like logged-in user account.
 */
import { createAction, handleActions } from "redux-actions";

import { DataStoreTypes } from "../../constants";
import { createSelector } from "reselect";
import { string } from "../../lib";

const { capitalize } = string;

const baseStore = (storeKey, makePayload) => {
  const STORE = `${storeKey}/STORE`;
  const ERASE = `${storeKey}/ERASE`;
  const REPLACE = `${storeKey}/REPLACE`;

  const actions = {
    [`store${capitalize(storeKey)}`]: makePayload
      ? createAction(STORE, makePayload)
      : createAction(STORE),
    [`replace${capitalize(storeKey)}`]: makePayload
      ? createAction(REPLACE, makePayload)
      : createAction(REPLACE),
    [`erase${capitalize(storeKey)}`]: createAction(ERASE),
  };

  const selector = state => state[storeKey];

  return { STORE, ERASE, REPLACE, actions, selector };
};

const keylessStore = (storeKey, opts = {}) => {
  const { reducerSeed = {}, initialState = null } = opts;
  const base = baseStore(storeKey);

  const handlers = {
    [base.STORE]: (state, action) => action.payload,
    [base.REPLACE]: (state, action) => action.payload,
    [base.ERASE]: () => null,
    ...reducerSeed
  };

  const reducer = {
    key: storeKey,
    reducer: handleActions(handlers, initialState)
  };

  return { actions: base.actions, selector: base.selector, reducer };
};

const keyedStore = (storeKey, opts = {}) => {
  const { reducerSeed = {}, initialState = {} } = opts;
  const base = baseStore(storeKey);

  const handlers = {
    [base.STORE]: (state, action) => {
      if (typeof action.payload === "object") {
        return { ...state, ...action.payload };
      }
      return state;
    },
    [base.REPLACE]: (state, action) => {
      if (typeof action.payload === "object") {

        return { ...action.payload };
      }
      return state;
    },
    [base.ERASE]: () => ({}),
    ...reducerSeed
  };

  const reducer = {
    key: storeKey,
    reducer: handleActions(handlers, initialState)
  };

  const baseSelectorName = `get${capitalize(storeKey)}`;
  const selectors = {
    [baseSelectorName]: base.selector,
    [`${baseSelectorName}AsArray`]: createSelector(base.selector, b =>
      Object.keys(b).map(key => b[key])
    )
  };

  return { actions: base.actions, selectors, reducer };
};

const mkDataStore =  (storeKey, type, opts) => {
  if (storeKey && typeof storeKey === "string") {
    return type === DataStoreTypes.Keyed
      ? keyedStore(storeKey, opts)
      : keylessStore(storeKey, opts);
  }
  throw new Error("No storeKey defined!");
};

export default mkDataStore;

// a helper to help with injecting UI data to server response
export const augmentResponse = (uiState, key = "ref") => response =>
  response
    .map(item => {
      if (uiState) {
        item._ui = { ...uiState };
      }
      return item;
    })
    .reduce(
      (acc, item) => ({
        ...acc,
        [item[key]]: item
      }),
      []
    );
