import "./BackLink.scss";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import React from "react";

const BackLink = ({ to }) => (
  <div className="BackTo">
    <FormattedMessage id="a.back">
      {msg => (
        <Link to={to}>
          <span> &lt; {msg} </span>
        </Link>
      )}
    </FormattedMessage>
  </div>
);

export const BackToAccount = () => <BackLink to={"/"} />;

export default BackLink;
