import "./Spinner.scss";

import PropTypes from "prop-types";
import React from "react";
import Spinkit from "react-spinkit";

const Spinner = ({ message, fadeIn }) => (
  <div className="Spinner">
    <Spinkit name="three-bounce" color="#0098d8" fadeIn={fadeIn} />
    {message && <p>{message}</p>}
  </div>
);

Spinner.propTypes = {
  message: PropTypes.node,
  fadeIn: PropTypes.string
};

Spinner.defaultProps = {
  message: null,
  fadeIn: "full"
};

export default Spinner;
