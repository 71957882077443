import * as yup from "yup";

export const ELECTRIC = "DIRECT";
export const PAPER = "PAPER";
export const EMAIL = "EMAIL";

export const template = overrides => ({
  type: ELECTRIC,
  ovt: "",
  serviceId: "",
  firstName: "",
  lastName: "",
  companyName: "",
  address: "",
  postalCode: "",
  postOffice: "",
  emailAddress: "",
  customInvoiceReference: "",
  ...overrides
});

export const schemas = intl => {
  const genericRequired = intl.formatMessage({
    id: "wizard.invoiceInfo.generic.required"
  });
  return yup.object({
    type: yup.string().required(),
    ovt: yup.string().when("type", {
      is: ELECTRIC,
      then: yup.string().required(genericRequired)
    }),
    serviceId: yup.string().when("type", {
      is: ELECTRIC,
      then: yup.string().required(genericRequired)
    }),
    emailAddress: yup.string().when("type", {
      is: EMAIL,
      then: yup
        .string()
        .email(
          intl.formatMessage({ id: "wizard.invoiceInfo.emailAddress.valid" })
        )
        .required(genericRequired)
    }),
    companyName: yup.string().when("type", {
      is: PAPER,
      then: yup.string().required(genericRequired)
    }),
    firstName: yup.string().when("type", {
      is: PAPER,
      then: yup.string().required(genericRequired)
    }),
    lastName: yup.string().when("type", {
      is: PAPER,
      then: yup.string().required(genericRequired)
    }),
    address: yup.string().when("type", {
      is: PAPER,
      then: yup.string().required(genericRequired)
    }),
    postalCode: yup.string().when("type", {
      is: PAPER,
      then: yup.string().required(genericRequired)
    }),
    postOffice: yup.string().when("type", {
      is: PAPER,
      then: yup.string().required(genericRequired)
    })
  });
};
