import { parsePhoneNumberFromString } from "libphonenumber-js";

export const validPhoneNumber = number => {
  if (number) {
    const phoneNumber = parsePhoneNumberFromString(number, "FI");
    if (phoneNumber) {
      return phoneNumber.isValid();
    }
  }
  return false;
};

const getInternationalPhoneNumber = number => {
  const phoneNumber = parsePhoneNumberFromString(number, "FI");
  return phoneNumber ? phoneNumber.number : number;
};

const phoneNumbers = {
  validPhoneNumber,
  getInternationalPhoneNumber
};

export default phoneNumbers;