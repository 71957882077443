import "./Voucher.scss";

import { PaginatedTable, Separator, Tags } from "..";

import AccountSummary from "../../components/AccountSummary";
import { FormattedMessage, useIntl } from "react-intl";
import { LoggedInEnterpriseHeader } from "../../components/EnterpriseHeader";
import React, { useEffect, useState } from "react";
import Search from "../Search";
import SendVoucher from "./GrantVoucher/SendVoucher";
import VoucherCosts from "./VoucherCosts";
import { formatIsoDate } from "../../lib/dateTime";
import { useDispatch, useSelector } from "react-redux";
import { voucherSelectors, voucherThunks } from "../../redux/vouchers";
import {
  enterpriseAccountSelector,
  enterpriseAccountThunks,
} from "../../redux/account";
import { Configuration } from "../../constants";
import { useDebounce } from "use-debounce";

const { View, Page, H1, P } = Tags;

export const grantTypes = {
  PHONE: "PHONE_NUMBER",
  LPN: "LICENSE_PLATE_NUMBER",
  BOTH: "ALL",
};

const yearAgo = new Date(
  new Date().getFullYear() - 1,
  new Date().getMonth(),
  new Date().getDay()
);

const pageSize = Configuration.USED_VOUCHERS_PAGESIZE;

const createVouchersAction = (page, search) => ({
  page,
  limit: pageSize,
  startDate: yearAgo.toISOString(),
  search,
});

const Voucher = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const account = useSelector(enterpriseAccountSelector);
  const voucherTemplates = useSelector(
    voucherSelectors.getVoucherTemplatesAsArray
  );
  const usedVouchers = useSelector(voucherSelectors.getUsedVouchers);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [debouncedSearch] = useDebounce(searchTerm, 700);

  useEffect(() => {
    document.title = "Moovy - Voucher";
    return () => (document.title = "Moovy - Yritystili");
  }, []);

  useEffect(() => {
    dispatch(
      voucherThunks.getUsedVouchers(createVouchersAction(0, debouncedSearch))
    );
  }, [dispatch, debouncedSearch]);

  useEffect(() => {
    if (!account) {
      dispatch(enterpriseAccountThunks.getAccount());
    }

    dispatch(voucherThunks.getVoucherTemplates());
  }, [account, dispatch]);

  const changeSearch = (ev) => {
    const search = ev.target.value;
    setSearchTerm(search);
    setCurrentPage(0);
  };

  const mapVoucherDataFromBackend = (voucher) => {
    const data = {
      ref: voucher.ref,
      allowedGrantType: voucher.allowedGrantType,
      description: voucher.description,
      location: voucher.validLocationsDescription,
      price: voucher.salePrice,
      validForMinutes: voucher.validForMinutes,
    };
    return data;
  };

  const availableVouchers = voucherTemplates.map((voucher) =>
    mapVoucherDataFromBackend(voucher)
  );

  const grantedVouchers =
    usedVouchers && usedVouchers.data ? usedVouchers.data : [];
  const totalResults =
    usedVouchers && usedVouchers.totalResults ? usedVouchers.totalResults : 0;

  return (
    <div>
      <LoggedInEnterpriseHeader />
      <Page>
        <View className="Voucher">
          <P>
            <FormattedMessage id="voucher.info" />
          </P>
          {!account && (
            <span>
              <FormattedMessage id="general.waiting" />
            </span>
          )}
          {account && (
            <>
              <div className="GiveVoucherContainer">
                <div className="Left">
                  <H1>
                    <FormattedMessage id="voucher.issuer.title" />
                  </H1>
                  <AccountSummary account={account} />
                  <P>
                    <FormattedMessage id="voucher.issuer.info" />
                  </P>
                </div>
                <div className="Right">
                  {availableVouchers && availableVouchers.length > 0 ? (
                    <SendVoucher availableVouchers={availableVouchers} />
                  ) : (
                    <H1>
                      <FormattedMessage id="voucher.no.vouchers" />
                    </H1>
                  )}
                </div>
              </div>
              <Separator />

              <div className="UsedVouchersContainer">
                <div className="Left">
                  <H1>
                    <FormattedMessage id="voucher.used.title" />
                  </H1>
                  <Search
                    name="voucherSearch"
                    placeholder={intl.formatMessage({
                      id: "voucher.used.search.placeholder",
                    })}
                    value={searchTerm}
                    onChange={changeSearch}
                  />
                  <PaginatedTable
                    rows={grantedVouchers}
                    renderRows={(rows) => (
                      <>
                        {rows.map((row) => (
                          <tr key={row.ref}>
                            <td>{formatIsoDate(row.grantedDate, "fi")}</td>
                            <td>{row.phoneNumber}</td>
                            <td>
                              {row.licensePlateNumber &&
                                row.licensePlateNumber.toUpperCase()}
                            </td>
                            <td>
                              {formatIsoDate(row.validFrom, "fi")} -{" "}
                              {formatIsoDate(row.validUntil, "fi")}
                            </td>
                            <td>{row.description}</td>
                          </tr>
                        ))}
                      </>
                    )}
                    headers={
                      <>
                        <tr>
                          <th>
                            <FormattedMessage id="voucher.used.headers.date" />
                          </th>
                          <th>
                            <FormattedMessage id="voucher.used.headers.phone" />
                          </th>
                          <th>
                            <FormattedMessage id="voucher.used.headers.licensePlateNumber" />
                          </th>
                          <th>
                            <FormattedMessage id="voucher.used.headers.validity" />
                          </th>
                          <th>
                            <FormattedMessage id="voucher.used.headers.description" />
                          </th>
                        </tr>
                      </>
                    }
                    totalResults={totalResults}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    changePage={(newPage) => {
                      setCurrentPage(newPage);
                      dispatch(
                        voucherThunks.getUsedVouchers(
                          createVouchersAction(newPage, searchTerm)
                        )
                      );
                    }}
                  />
                </div>
                {Configuration.SHOW_VOUCHER_COSTS && (
                  <div className="Right">
                    <H1>
                      <FormattedMessage id="voucher.costs.title" />
                    </H1>
                    <VoucherCosts />
                  </div>
                )}
              </div>
            </>
          )}
        </View>
      </Page>
    </div>
  );
};

export default Voucher;
