import PropTypes from "prop-types";

export const messageShape = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.object
  })
};

export const notificationShape = {
  type: PropTypes.string,
  message: PropTypes.shape(messageShape)
};
