import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const CheckCircle = ({ checked, error }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(
      "Checkbox__unchecked",
      error ? "Checkbox__invalid" : ""
    )}
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M12 22.933c6.038 0 10.933-4.895 10.933-10.933S18.038 1.067 12 1.067 1.067 5.962 1.067 12 5.962 22.933 12 22.933z"
      />
      <path
        fill="#0098D8"
        fillRule="nonzero"
        d="M12 22.933c6.038 0 10.933-4.895 10.933-10.933S18.038 1.067 12 1.067 1.067 5.962 1.067 12 5.962 22.933 12 22.933zM12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z"
      />
      {checked && <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM6.97322 12.1551C6.66082 12.4711 6.65864 12.9782 6.96907 13.2887L10.2795 16.5991C10.5896 16.9092 11.0955 16.9061 11.4106 16.591L18.191 9.81055C18.5056 9.49599 18.5012 8.98162 18.1974 8.67777L17.3398 7.82023C17.0288 7.50917 16.5208 7.51279 16.2058 7.8278L11.1108 12.9228C10.9523 13.0814 10.6914 13.0776 10.5415 12.9276L8.93387 11.3201C8.6187 11.0049 8.10753 11.0079 7.79755 11.3214L6.97322 12.1551Z" fill="#0098D8" />}
    </g>
  </svg>
);

CheckCircle.propTypes = {
  checked: PropTypes.bool
};

CheckCircle.defaultProps = {
  checked: false
};

export default CheckCircle;
