const endpoint = path => `/enterprise/v1/subscribedServices${path}`;

const assignments = ({ get, post, put }) => ({
  getAssignments: (params, opts) =>
    get(endpoint(`/${opts.ref}/assignments`), params, opts),
  postAddAssignment: (data, opts) => {
    data.phoneNumber = data.phoneNumber.replace(/\s+/g, '');
    return post(
      endpoint(`/${opts.pathParams.subscribedServiceRef}/addAssignment`),
      data,
      opts
    );
  },
  putModifyAssignment: (data, opts) => {
    return put(
      endpoint(`/${opts.pathParams.subscribedServiceRef}/assignments/${
        opts.pathParams.assignmentRef
        }/modify`),
      data,
      opts
    );
  },
  postRevokeAssignment: (data, opts) =>
    post(
      endpoint(
        `/${opts.pathParams.subscribedServiceRef}/assignments/${
        opts.pathParams.assignmentRef
        }/revoke`,
        data,
        opts
      )
    )
});

export default assignments;