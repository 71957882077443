import "./EditAssignment.scss";

import { Input, Tags } from "../..";
import React, { useEffect, useRef } from "react";
import {
  assignmentsActions,
  assignmentsSelectors,
  assignmentsThunks,
} from "../../../redux/userAssignments";
import { schema, template } from "./editSubscription.schema";
import { useDispatch, useSelector } from "react-redux";

import { AssignedUserStatus } from "../../../constants";
import { FormattedMessage } from "react-intl";
import { getError } from "../../../lib/form";
import { useFormik } from "formik";
import { subscribedServicesThunks } from "../../../redux/subscribedServices";

const { LinkButton } = Tags;

const getUserStatus = (user) => {
  switch (user.status) {
    case AssignedUserStatus.PENDING:
      return (
        <div>
          <FormattedMessage id="manage_subscription.pending">
            {(msg) => <p className="ManageSubscription__attention">{msg}</p>}
          </FormattedMessage>
        </div>
      );
    case AssignedUserStatus.ACCEPTED:
      return <FormattedMessage id="manage_subscription.allocated" />;
    default:
      return <FormattedMessage id="manage_subscription.unknown" />;
  }
};

const EditAssignment = ({
  subscribedServiceRef,
  user,
  afterEdit,
  assignmentsRemaining,
  intl,
}) => {
  const inputEl = useRef(null);
  const dispatch = useDispatch();
  const assignments = useSelector((state) =>
    assignmentsSelectors.getAssignments(state)
  );

  const availableCapacity = user.maxActiveVehicles + assignmentsRemaining;

  // Focus on the first input on mount
  useEffect(() => inputEl.current.focus(), []);

  const formik = useFormik({
    initialValues: template(user),
    validationSchema: schema(intl, availableCapacity),
    onSubmit: async (values) => {
      await dispatch(
        assignmentsThunks.modifyAssignment(
          values,
          subscribedServiceRef,
          user.ref
        )
      );
      // Backend expects the customName in a field called 'name'. Assignments that come back from backend have the field as 'customName'
      const newState = {
        ...assignments,
        [user.ref]: { ...user, ...values, customName: values.name },
      };
      dispatch(assignmentsActions.storeAssignments(newState));
      dispatch(subscribedServicesThunks.getSubscribedServices());
      afterEdit(values);
    },
  });

  return (
    <tr
      className="editAssignment"
      onKeyDown={(e) => {
        if (e.nativeEvent.key === "Escape") {
          afterEdit();
        } else if (e.nativeEvent.key === "Enter") {
          formik.submitForm();
        }
      }}
    >
      <td className="editable">
        <Input
          inputRef={inputEl}
          className="editName"
          type="text"
          name="name"
          value={formik.values.name}
          error={getError(formik.touched, formik.errors, "name")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </td>
      <td>{user.phoneNumber}</td>
      <td className="editable">
        <Input
          className="editMaxVehicles"
          type="number"
          min={1}
          max={availableCapacity}
          name="maxActiveVehicles"
          value={formik.values.maxActiveVehicles}
          error={getError(formik.touched, formik.errors, "maxActiveVehicles")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </td>
      <td className="editable">
        <Input
          className="editAdditionalInfo"
          type="text"
          name="additionalInfo"
          value={formik.values.additionalInfo}
          error={getError(formik.touched, formik.errors, "additionalInfo")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </td>
      <td>{getUserStatus(user)}</td>
      <td>
        <LinkButton onClick={afterEdit}>
          <FormattedMessage id="a.cancel">
            {(msg) => `${msg} >`}
          </FormattedMessage>
        </LinkButton>
        <br />
        <LinkButton
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting || !formik.isValid}
          type="button"
        >
          <FormattedMessage id="a.submit">
            {(msg) => `${msg} >`}
          </FormattedMessage>
        </LinkButton>
      </td>
    </tr>
  );
};

export default EditAssignment;
