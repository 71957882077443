import "./Search.scss";

import { Input } from "..";
import React from "react";
import { injectIntl } from "react-intl";

const Search = ({ intl, onSearchClick, ...props }) => (
  <div className="Search">
    <Input
      type="text"
      placeholder={intl.formatMessage({
        id: "manage_subscription.search.placeholder"
      })}
      {...props}
    />
    <div className="Icon" onClick={onSearchClick}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="IconSearchGrey">
          <rect
            id="Rectangle"
            x="9.19238"
            y="11.3137"
            width="1"
            height="7"
            rx="0.5"
            transform="rotate(45 9.19238 11.3137)"
            stroke="#555555"
          />
          <path
            id="Oval 2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.48539 4.24264C6.14224 6.58579 6.14224 10.3848 8.48539 12.7279C10.8285 15.0711 14.6275 15.0711 16.9707 12.7279C19.3138 10.3848 19.3138 6.58579 16.9707 4.24264C14.6275 1.89949 10.8285 1.89949 8.48539 4.24264ZM15.5565 11.3137C13.9944 12.8758 11.4617 12.8758 9.8996 11.3137C8.3375 9.75161 8.3375 7.21895 9.8996 5.65685C11.4617 4.09476 13.9944 4.09476 15.5565 5.65685C17.1186 7.21895 17.1186 9.75161 15.5565 11.3137Z"
            fill="#555555"
          />
        </g>
      </svg>
    </div>
  </div>
);

export default injectIntl(Search);
