import "./Counter.scss";

import PropTypes from "prop-types";
import React from "react";
import SVG from "../SVG";
import classNames from "classnames";

const Counter = ({
  value,
  onIncrease,
  onDecrease,
  onChange,
  className = ""
}) => {
  return (
    <div className={classNames("Counter", className)}>
      <div className="Counter__content">
        <button
          className="Counter__button"
          onClick={onDecrease}
        >
          <SVG.XXcrementor type="decrementor" />
        </button>
        <p className="Counter__value">
          <input
            value={value}
            className="Counter__input"
            onChange={onChange}
            disabled={!onChange}
            size={value ? value.toPrecision().length : 1}
          />
        </p>
        <button
          className="Counter__button"
          onClick={onIncrease}
        >
          <SVG.XXcrementor type="incrementor" />
        </button>
      </div>
    </div>
  );
};

Counter.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
  onChange: PropTypes.func
};

export default Counter;
