import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import Footer from "./components/Footer";
import MainPageContent from "./pages/MainPage";
import ManageCompanyInfo from "./pages/ManageCompanyInfo";
import ManageContactInfo from "./pages/ManageContactInfo";
import ManageInvoicing from "./pages/ManageInvoicing";
import ManageSubscribedService from "./pages/ManageSubscribedService";
import ModifySubscribedService from "./pages/ModifySubscribedService";
import OrderConfirmation from "./pages/OrderConfirmation";

import React from "react";
import SignupContactInfo from "./components/SignupContactInfo";
import SignupCredentials from "./components/SignupCredentials";
import SignupInvoiceInfo from "./components/SignupInvoiceInfo";
import Voucher from "./pages/Voucher";
import RequireAuth from "./components/RequireAuth";

const MoovyRoutes = () => (
  <Router>
    <Routes>
      <Route path="/signup" element={<SignupContactInfo />} />

      <Route path="/signup/invoice" element={<SignupInvoiceInfo />} />

      <Route path="/signup/credentials" element={<SignupCredentials />} />

      <Route
        path="/"
        element={
          <RequireAuth>
            <MainPageContent />
          </RequireAuth>
        }
      />

      <Route
        path="/contactinfo"
        element={
          <RequireAuth>
            <ManageContactInfo />
          </RequireAuth>
        }
      />

      <Route
        path="/company"
        element={
          <RequireAuth>
            <ManageCompanyInfo />
          </RequireAuth>
        }
      />

      <Route
        path="/orderconfirmation/:serviceRef"
        element={
          <RequireAuth>
            <OrderConfirmation />
          </RequireAuth>
        }
      />

      <Route
        path="/managesubscription/:subscribedServiceRef"
        element={
          <RequireAuth>
            <ManageSubscribedService />
          </RequireAuth>
        }
      />

      <Route
        path="/modifysubscribedservice/:subscribedServiceRef"
        element={
          <RequireAuth>
            <ModifySubscribedService />
          </RequireAuth>
        }
      />

      <Route
        path="/invoice"
        element={
          <RequireAuth>
            <ManageInvoicing />
          </RequireAuth>
        }
      />

      <Route
        path="/voucher"
        element={
          <RequireAuth>
            <Voucher />
          </RequireAuth>
        }
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    <Footer />
  </Router>
);

export default MoovyRoutes;
