import { createSelector } from "reselect";
import { string } from "../lib";

const { capitalize } = string;

const createBaseSelectors = (key, initialState) => {
  const rootSelector = state => state[key];

  return Object.keys(initialState).reduce(
    (selectors, stateKey) => ({
      ...selectors,
      [`get${capitalize(stateKey)}`]: createSelector(
        rootSelector,
        r => r[stateKey]
      )
    }),
    {}
  );
};

export default createBaseSelectors;