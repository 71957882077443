import React from "react";
import { injectIntl } from "react-intl";
import DatePicker, { registerLocale } from "react-datepicker";
import fi from "date-fns/locale/fi";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("fi", fi);

function VoucherStartTimePicker({ customInput, value, onChange, intl }) {
  return (
    <div>
      <span className="Label">
        {intl.formatMessage({ id: "voucher.validFrom.label" })}
      </span>
      <DatePicker
        selected={value}
        value={value}
        onChange={onChange}
        showTimeSelect
        timeIntervals={15}
        timeCaption="Klo"
        locale="fi"
        dateFormat="d.M.yyyy HH:mm"
        customInput={customInput}
      />
    </div>
  );
}

export default injectIntl(VoucherStartTimePicker);
