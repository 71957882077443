import * as yup from "yup";

import { validPhoneNumber } from "../../../lib/phoneNumbers";

export const template = user => ({
  name: "",
  phone: "+358",
  additionalInfo: "",
  subscribedServiceRef: user.subscribedServiceRef,
  maxActiveVehicles: 1
});

export const schema = (intl, assignmentsRemaining) =>
  yup.object().shape({
    name: yup
      .string()
      .required(intl.formatMessage({ id: "manage_subscription.name.required" }))
      .min(2, intl.formatMessage({ id: "manage_subscription.name.valid" })),
    phone: yup
      .string()
      .required(
        intl.formatMessage({ id: "manage_subscription.phone.required" })
      )
      .test(
        "valid-phone",
        intl.formatMessage({ id: "manage_subscription.phone.valid" }),
        validPhoneNumber
      ),
    additionalInfo: yup
      .string()
      .max(
        100,
        intl.formatMessage({ id: "manage_subscription.additionalInfo.max" })
      ),
    maxActiveVehicles: yup
      .number()
      .required(
        intl.formatMessage({
          id: "manage_subscription.maxActiveVehicles.required"
        })
      )
      .min(
        1,
        intl.formatMessage({ id: "manage_subscription.maxActiveVehicles.min" })
      )
      .max(
        assignmentsRemaining,
        intl.formatMessage(
          { id: "manage_subscription.maxActiveVehicles.max" },
          { availableCapacity: assignmentsRemaining }
        )
      )
  });
