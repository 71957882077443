import "./AssignmentsTable.scss";

import { FormattedMessage, injectIntl } from "react-intl";
import { PaginatedTable, Spinner, Tags } from "../..";
import React, { useState } from "react";

import AssignedUser from "../AssignedUser";
import EditAssignment from "../EditAssignment";
import PropTypes from "prop-types";
import Search from "../../Search";
import UnassignedUser from "../UnassignedUser";
import { subscriptionUserAssignmentShape } from "../../../shapes/subscriptionUserAssignmentShape";

const { H1 } = Tags;

const AssignmentsTable = ({
  subscribedServiceRef,
  assignments,
  assignUser,
  revokeUser,
  unassignedNameRef,
  assignmentsRemaining,
  pending,
  intl
}) => {
  const [editRef, setEditRef] = useState(undefined);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;

  const handleSearch = ev => {
    const s = ev.target.value.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
    setSearch(s);
  };

  const filterAssignments = () => {
    const regex = new RegExp(search, "i");
    return assignments.filter(
      a =>
        regex.test(a.customName) ||
        regex.test(a.phoneNumber) ||
        regex.test(a.additionalInfo)
    );
  };

  const changePage = newPage => {
    setCurrentPage(newPage);
  };

  const filteredAssignments = search ? filterAssignments(search) : assignments;

  const renderRows = rows => {
    return (
      <React.Fragment>
        {rows.map(user => (
          <React.Fragment key={user.ref}>
            {editRef === user.ref ? (
              <EditAssignment
                subscribedServiceRef={subscribedServiceRef}
                user={user}
                assignmentsRemaining={assignmentsRemaining}
                key={user.ref}
                afterEdit={() => setEditRef(undefined)}
                intl={intl}
              />
            ) : (
              <AssignedUser
                subscribedServiceRef={subscribedServiceRef}
                user={user}
                revokeUser={revokeUser}
                key={user.ref}
                onStartEdit={() => setEditRef(user.ref)}
              />
            )}
          </React.Fragment>
        ))}
        {assignmentsRemaining > 0 && (
          <UnassignedUser
            user={{ name: "", phone: "", subscribedServiceRef }}
            assignUser={assignUser}
            unassignedNameRef={unassignedNameRef}
            assignmentsRemaining={assignmentsRemaining}
          />
        )}
      </React.Fragment>
    );
  };

  const rowsOnPage = filteredAssignments.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

  return (
    <div className="AssignmentsTable">
      <H1>
        <FormattedMessage id="manage_subscription.table_title" />
      </H1>
      <form onSubmit={filterAssignments}>
        <Search
          name="assignmentSearch"
          onChange={handleSearch}
          onSearchClick={filterAssignments}
        />
      </form>
      <PaginatedTable
        rows={rowsOnPage}
        headers={
          <tr>
            <th>
              <FormattedMessage id="manage_subscription.name" />
            </th>
            <th className="PhoneNumber">
              <FormattedMessage id="manage_subscription.phone" />
            </th>
            <th>
              <FormattedMessage id="manage_subscription.activeVehicles.max" />
            </th>
            <th className="AdditionalInfoHeader">
              <FormattedMessage id="manage_subscription.additionalInfo" />
            </th>
            <th>
              <FormattedMessage id="manage_subscription.state" />
            </th>
            <th />
          </tr>
        }
        renderRows={renderRows}
        totalResults={filteredAssignments.length}
        pageSize={pageSize}
        currentPage={currentPage}
        changePage={changePage}
      />
      {pending && (
        <div className="AssignmentsTable__spinner">
          <Spinner
            message={<FormattedMessage id="general.waiting" />}
            fadeIn="none"
          />
        </div>
      )}
    </div>
  );
};

AssignmentsTable.propTypes = {
  subscribedServiceRef: PropTypes.string.isRequired,
  assignments: PropTypes.arrayOf(
    PropTypes.shape(subscriptionUserAssignmentShape)
  ).isRequired,
  assignUser: PropTypes.func.isRequired,
  revokeUser: PropTypes.func.isRequired,
  assignmentsRemaining: PropTypes.number.isRequired
};

export default injectIntl(AssignmentsTable);
