import { FormattedMessage, useIntl } from "react-intl";
import { schema, template } from "./Credentials.schema";
import {
  signupParametersActions,
  signupParametersSelector,
} from "../../redux/signup";

import CredentialsForm from "./CredentialsForm";
import EnterpriseHeader from "../EnterpriseHeader";
import { Formik } from "formik";
import { NotificationTypes } from "../../constants";
import React from "react";
import SignupProgress from "../SignupProgress";
import { Tags } from "..";
import { useDispatch, useSelector } from "react-redux";
import { enterpriseAccountThunks } from "../../redux/account";
import { notifications } from "../../redux/common";
import { useNavigate } from "react-router-dom";

const { View, FormButton, Page } = Tags;

const Credentials = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const signupParameters = useSelector(signupParametersSelector);
  const navigate = useNavigate();

  if (!signupParameters || !signupParameters.invoiceInfo) {
    navigate("/signup");
    return null;
  }

  const initialValues = signupParameters.credentials
    ? signupParameters.credentials
    : template();

  const { storeSignupparameters } = signupParametersActions;

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const oldParameters = signupParameters;
    dispatch(storeSignupparameters({ ...oldParameters, credentials: values }));
    const newParameters = { ...signupParameters, ...values };
    const { setNotification } = notifications.actions;
    dispatch(enterpriseAccountThunks.createAccount(newParameters))
      .finally(() => setSubmitting(false))
      // replace history, so account is not accidentally created twice
      .then(() => navigate("/account", { replace: true }))
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          const message = err.response.data.message;
          // Username or email was already taken in keycloak
          if (message === "username already taken") {
            dispatch(
              setNotification(
                { id: "wizard.sendAccount.conflict.username" },
                NotificationTypes.Error
              )
            );
          } else if (message === "email already taken") {
            dispatch(
              setNotification(
                { id: "wizard.sendAccount.conflict.email" },
                NotificationTypes.Error
              )
            );
          } else {
            dispatch(
              setNotification(
                { id: "wizard.sendAccount.conflict" },
                NotificationTypes.Error
              )
            );
          }
        } else {
          dispatch(
            setNotification(
              { id: "wizard.sendAccount.error" },
              NotificationTypes.Error
            )
          );
        }
      });
  };

  return (
    <div>
      <EnterpriseHeader title={<FormattedMessage id="wizard.title" />}>
        <SignupProgress step={3} />
      </EnterpriseHeader>
      <Page>
        <View>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={schema(intl)}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
            }) => (
              <form className="ContactInfo__form">
                <div className="ContactInfo__formBlockContainer">
                  {[CredentialsForm].map((form) =>
                    form({
                      confirmPassword: true,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      errors,
                      intl,
                    })
                  )}
                </div>
                <FormButton
                  onClick={handleSubmit}
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="wizard.createAccount" />
                </FormButton>
              </form>
            )}
          </Formik>
        </View>
      </Page>
    </div>
  );
};

export default Credentials;
