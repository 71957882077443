import "./MainPage.scss";

import { SubscribedServiceCard, Tags } from "..";
import { servicesSelectors } from "../../redux/services";
import { subscribedServicesSelectors } from "../../redux/subscribedServices";

import AccountSummary from "../../components/AccountSummary";
import { FormattedMessage } from "react-intl";
import ProductOffering from "./ProductOffering";
import React from "react";
import { useSelector } from "react-redux";
import { subscribedService2Card } from "../../lib/helpers";
import { useNavigate } from "react-router-dom";

const { View, H1 } = Tags;

// TODO: Just move this to the MainPage page?
const MainPage = ({ account }) => {
  const subscribedServices = useSelector(
    subscribedServicesSelectors.getSubscribedServicesAsArray
  );
  const services = useSelector(servicesSelectors.getServicesAsArray);
  const sortedSubscribedServices = [...subscribedServices].sort((a, b) =>
    a.service.name.localeCompare(b.service.name)
  );

  return (
    <View className="Account">
      <div className="Account__current">
        <AccountSummary account={account} />
        <div>
          <H1 className="Account__subtitle">
            <FormattedMessage id="account.subscribed_services" />
          </H1>
          <SubscribedServices
            subscribedServices={sortedSubscribedServices}
            services={services}
          />
        </div>
      </div>

      <ProductOffering account={account} />
    </View>
  );
};

const SubscribedServices = ({ subscribedServices, services }) => {
  const navigate = useNavigate();
  if (!services || services.length === 0) {
    return null;
  }

  const cardData = subscribedServices
    .sort((a, b) => a.service.name.localeCompare(b.service.name))
    .map((subscribedService) =>
      subscribedService2Card(
        subscribedService,
        services.find(
          (service) => service.ref === subscribedService.service.ref
        )
      )
    );

  return (
    <div className="Account__serviceList">
      {cardData.map((data) => (
        <SubscribedServiceCard
          key={data.ref}
          data={data}
          actionLabel={
            <FormattedMessage id="subscribedServiceCard.manageSubscription" />
          }
          action={() => {
            navigate(`/managesubscription/${data.ref}`);
          }}
          theme={data.publicService && !data.hasDiscounts ? "" : "gold"}
        />
      ))}
    </div>
  );
};

export default MainPage;
