import { FormattedMessage } from "react-intl";
import {
  signupParametersActions,
  signupParametersSelector,
} from "../redux/signup";

import EnterpriseHeader from "./EnterpriseHeader";
import InvoiceInformationForm from "./InvoiceInformationForm";
import React, { useEffect } from "react";
import SignupProgress from "./SignupProgress";
import { Tags } from ".";
import { useDispatch, useSelector } from "react-redux";
import { signUpToInvoicing } from "../lib/helpers";
import { useNavigate } from "react-router-dom";

const { View, Page } = Tags;

const InvoiceInfo = () => {
  const dispatch = useDispatch();
  const signupParameters = useSelector(signupParametersSelector);
  const navigate = useNavigate();

  const { storeSignupparameters } = signupParametersActions;

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const oldParameters = signupParameters;
    dispatch(storeSignupparameters({ ...oldParameters, invoiceInfo: values }));
    navigate("/signup/credentials");
  };

  useEffect(() => {
    if (!signupParameters?.company || !signupParameters?.contactPerson) {
      navigate("/signup");
    }
  }, [navigate, signupParameters?.company, signupParameters?.contactPerson]);

  if (!signupParameters) {
    return null;
  }

  const initialValues = signUpToInvoicing(signupParameters);
  const { invoiceInfo } = signupParameters;

  return (
    <div>
      <EnterpriseHeader title={<FormattedMessage id="wizard.title" />}>
        <SignupProgress step={2} />
      </EnterpriseHeader>
      <Page>
        <View>
          <InvoiceInformationForm
            initialValues={invoiceInfo ? invoiceInfo : initialValues}
            onSubmit={onSubmit}
          />
        </View>
      </Page>
    </div>
  );
};

export default InvoiceInfo;
