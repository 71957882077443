import "./SpinnerBlock.scss";

import PropTypes from "prop-types";
import React from "react";
import Spinner from "../Spinner";

const SpinnerBlock = ({ message }) => (
  <div className="SpinnerBlock">
    <Spinner message={message} />
  </div>
);

SpinnerBlock.propTypes = {
  message: PropTypes.node
};

SpinnerBlock.defaultProps = {
  message: null
};

export default SpinnerBlock;
