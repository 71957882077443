import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import React from "react";
import { currency } from "../../lib";

export const subscribedServiceShape = PropTypes.shape({
  subscriptionCount: PropTypes.string,
  assignmentCount: PropTypes.string,
  assignmentsRemaining: PropTypes.string,
  pricePerMonth: PropTypes.string,
  subscriptionsEnding: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      count: PropTypes.number
    })
  )
});

const SubscribedService = ({ subscribedService }) => (
  <div className="SubscribedServiceCard__ContentInner__subscription">
    {subscribedService.startDate && (
      <p style={{ marginBottom: "12px" }}>
        <FormattedMessage
          id="subscribedServiceCard.subscribedService.date"
          values={{ date: subscribedService.startDate }}
        />
      </p>
    )}

    <p>
      <FormattedMessage
        id="subscribedServiceCard.subscribedService.count"
        values={{ count: subscribedService.subscriptionCount }}
      />
    </p>

    {subscribedService.subscriptionsEnding &&
      subscribedService.subscriptionsEnding.length > 0 && (
        <p style={{ color: "red" }}>
          <FormattedMessage id="subscribedServiceCard.subscribedService.subscriptionsEnding" />
          {subscribedService.subscriptionsEnding.map((end, index, array) => (
            <span key={end.date}>
              {`${end.count} (${end.date})`}
              {index + 1 < array.length ? ", " : ""}
            </span>
          ))}
        </p>
      )}

    {subscribedService.assignmentCount && (
      <p>
        <FormattedMessage
          id="subscribedServiceCard.subscribedService.assignmentCount"
          values={{ count: subscribedService.assignmentCount }}
        />
      </p>
    )}

    {subscribedService.assignmentsRemaining && (
      <p>
        <FormattedMessage
          id="subscribedServiceCard.subscribedService.assignmentsRemaining"
          values={{ count: subscribedService.assignmentsRemaining }}
        />
      </p>
    )}

    {subscribedService.pricePerMonth && (
      <p style={{ marginTop: "12px" }}>
        <FormattedMessage
          id="subscribedServiceCard.subscribedService.price"
          values={{
            price: currency.formatPrice(subscribedService.pricePerMonth)
          }}
        />
      </p>
    )}
  </div>
);

SubscribedService.propTypes = {
  subscribedService: subscribedServiceShape
};

export default SubscribedService;
