import { servicesThunks } from "../redux/services";
import { subscribedServicesThunks } from "../redux/subscribedServices";

import ModifySubscribedService from "../components/SubscribedService/ModifySubscribedService";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { notifications } from "../redux/common";
import { purchasesThunks } from "../redux/purchases";
import { subscriptionsThunks } from "../redux/subscriptions";

export default compose(
  connect(null, {
    ...purchasesThunks,
    ...servicesThunks,
    ...subscriptionsThunks,
    ...subscribedServicesThunks,
    ...notifications.actions,
  }),
  injectIntl
)(ModifySubscribedService);
