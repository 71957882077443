import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enterpriseAccountSelector, enterpriseAccountThunks } from "./account";

/* Get the current enterprise account. Will fetch the account if undefined */
const useAccount = () => {
  const account = useSelector(enterpriseAccountSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!account) {
      dispatch(enterpriseAccountThunks.getAccount());
    }
  }, [account, dispatch]);

  return account;
};

export default useAccount;
