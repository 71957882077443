import "./VoucherCosts.scss";

import React, { useState } from "react";

import Arrow from "./Arrow";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Tags } from "..";
import classNames from "classnames";
import { voucherCostsDataShape } from "../../shapes/voucherCostsData";
import { useSelector } from "react-redux";
import { voucherSelectors } from "../../redux/vouchers";

const { P } = Tags;

const getMonthText = (subtractMonths) => {
  const date = new Date();
  date.setMonth(date.getMonth() - subtractMonths);
  const dateString = date.toLocaleDateString("fi", {
    year: "numeric",
    month: "long",
  });
  return dateString.charAt(0).toUpperCase() + dateString.substring(1);
};

const sumAmounts = (months) =>
  months.map((cost) => cost.amount).reduce((acc, current) => acc + current);

const formatAmount = (amount) =>
  amount.toLocaleString("fi", {
    style: "currency",
    currency: "EUR",
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const VoucherCosts = ({ data }) => {
  const costs = useSelector(voucherSelectors.getVoucherCosts);
  const [benefitMonth, setBenefitMonth] = useState(0);
  const [costMonth, setCostMonth] = useState(0);

  if (!costs) {
    return null;
  }

  const { monthlyCustomerBenefits, monthlyCosts } = data;
  const benefitAmount = monthlyCustomerBenefits[benefitMonth].amount;
  const costAmount = monthlyCosts[costMonth].amount;

  const benefitAmountSummed = sumAmounts(monthlyCustomerBenefits);
  const costAmountSummed = sumAmounts(monthlyCosts);

  const previousBenefitMonth = () => {
    if (monthlyCustomerBenefits.length > benefitMonth + 1) {
      setBenefitMonth(benefitMonth + 1);
    }
  };

  const previousCostMonth = () => {
    if (monthlyCosts.length > costMonth + 1) {
      setCostMonth(costMonth + 1);
    }
  };

  const nextBenefitMonth = () => {
    if (benefitMonth > 0) {
      setBenefitMonth(benefitMonth - 1);
    }
  };

  const nextCostMonth = () => {
    if (costMonth > 0) {
      setCostMonth(costMonth - 1);
    }
  };

  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - monthlyCosts.length);

  return (
    <div className="VoucherCosts">
      <div className={classNames("Row", "MarginBottom1")}>
        <div className="Chevron" onClick={previousBenefitMonth}>
          <Arrow />
        </div>
        <div>
          <P className="CostSubHeader">{getMonthText(benefitMonth)}</P>
        </div>
        <div className="Chevron" onClick={nextBenefitMonth}>
          <Arrow flipped />
        </div>
      </div>
      <div className={classNames("Row", "MarginBottom2")}>
        <P className="CostHeader">{formatAmount(benefitAmount)}</P>
      </div>
      <div className="Row">
        <P className="CostSubHeader">
          {fromDate.toLocaleDateString("fi")}{" "}
          <FormattedMessage id="voucherCosts.from.text" />
        </P>
      </div>
      <div className={classNames("Row", "MarginBottom2")}>
        <P className="CostHeader">{formatAmount(benefitAmountSummed)}</P>
      </div>
      <div className={classNames("Row", "MarginBottom1")}>
        <P className="CostSubHeader">
          <FormattedMessage id="voucherCosts.costs.to.giver" />
        </P>
      </div>
      <div className={classNames("Row", "MarginBottom1")}>
        <div className="Chevron" onClick={previousCostMonth}>
          <Arrow />
        </div>
        <div>
          <P className="CostSubHeader">{getMonthText(costMonth)}</P>
        </div>
        <div className="Chevron" onClick={nextCostMonth}>
          <Arrow flipped />
        </div>
      </div>
      <div className={classNames("Row", "MarginBottom2")}>
        <P className="CostHeader">{formatAmount(costAmount)}</P>
      </div>
      <div className={classNames("Row", "MarginBottom1")}>
        <P className="CostSubHeader">
          {fromDate.toLocaleDateString("fi")}{" "}
          <FormattedMessage id="voucherCosts.from.text" />
        </P>
      </div>
      <div className="Row">
        <P className="CostHeader">{formatAmount(costAmountSummed)}</P>
      </div>
    </div>
  );
};

VoucherCosts.propTypes = {
  data: PropTypes.shape(voucherCostsDataShape).isRequired,
};

export default VoucherCosts;
